import { Link, Navigate, NavLink } from "react-router-dom";
import css from './health.module.css';
import { useContext, useEffect, useState } from "react";
import Loading from "../utils/Loading";
import { AxiosResponse } from "axios";
import { fetchNumberOfDrivers } from "../services/LinqrsApi";
import { FaAngleLeft } from "react-icons/fa";
import { NumberOfPeopleDTO } from "./health.model";
import { HealthContext } from "./healthContext";
import { NUMBER_OF_PEOPLES } from "../utils/GlobalConstants";
import { InsuranceContext } from "../insurance/insuranceContext";

export default function HealthPeople(props: healthPeopleProps) {

    const { state, dispatch } = useContext(HealthContext);
    const context = useContext(InsuranceContext);
    const [peoples, setPeoples] = useState<NumberOfPeopleDTO[]>([]);

    let titleDescr = `How many people need ${context.state.QuoteType.description.toLowerCase()} insurance?`;

    useEffect(() => {

        const fetchNumberOfPeopleDataAsync = async () => {

            var numberOfPeoplesSessionData = sessionStorage.getItem(NUMBER_OF_PEOPLES);

            if (numberOfPeoplesSessionData === null) {
                const response: AxiosResponse<NumberOfPeopleDTO[]> = await fetchNumberOfDrivers();
                var data = response.data;
                sessionStorage.setItem(NUMBER_OF_PEOPLES, JSON.stringify(data));
                setPeoples(data);
            }
            else {
                setPeoples(JSON.parse(numberOfPeoplesSessionData));
            }
        }

        fetchNumberOfPeopleDataAsync();

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetNumberOfPeoples(numberOfPeople: NumberOfPeopleDTO) {

        numberOfPeople.isSelected = !numberOfPeople.isSelected;

        dispatch({
            type: "HEALTH_PEOPLE",
            payload: numberOfPeople
        })

        props.onClick();
    }

    return (
        <>
            <div><NavLink to={`/signup`}><FaAngleLeft className={css.backArrow} /></NavLink> </div>
            <div className='mb-3'>
                <div className={css.divCenterText}>{titleDescr}</div>
            </div>
            {
                peoples && peoples.length > 0 ? (<div className={css.container}>
                    {
                        peoples.map((people) =>
                            <div className={people.description === state.NumberOfPeople?.description ? 'mb-3 ' + css.containerUnSelected : css.containerSelected + ' mb-3'} key={people.description} onClick={() => SetNumberOfPeoples(people)}>
                                {people.description}
                            </div>
                        )
                    }
                </div>) :
                  <Loading />
            }
        </>
    )
}

interface healthPeopleProps {
    onClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}