import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";


enum StatusCode {
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    TooManyRequests = 429,
    InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Credentials": false,
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest"
};

class Http {
    private instance: AxiosInstance | null = null;
    private accessInstance: AxiosInstance | null = null;

    private get http(): AxiosInstance {
        return this.instance != null ? this.instance : this.initHttp();
    }

    private get accessHttp(): AxiosInstance {
        return this.accessInstance != null ? this.accessInstance : this.initAccessHttp();
    }

    initHttp() {
        const http = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers,
            withCredentials: false,
        });

        http.interceptors.request.use(async (config) => {

            let originalRequest = config;

            if (process.env.NODE_ENV === 'production') {
                const user = JSON.parse(localStorage.getItem('token') || '{}');

                var tokenExpired = !(new Date().getTime() <= new Date(user.expiresOn).getTime());

                if (originalRequest.headers != null) {
                    if (tokenExpired === false) {
                        originalRequest.headers.Authorization = 'Bearer ' + user.accessToken;
                        originalRequest.headers['Ocp-Apim-Subscription-Key'] = user.subscriptionKey;
                    }
                    else {
                        const refreshToken = await this.refreshAccessToken()
                        originalRequest.headers.Authorization = 'Bearer ' + refreshToken.data.accessToken;
                        originalRequest.headers['Ocp-Apim-Subscription-Key'] = refreshToken.data.subscriptionKey;
                        return Promise.resolve(originalRequest);
                    }

                }
            }

            return config;
        }, (err) => {
            return Promise.reject(err);
        });

        http.interceptors.response.use(
            (response) => response,
            (error) => {
                return Promise.reject(error);
            }
        );

        this.instance = http;
        return http;
    }

    initAccessHttp() {
        const accessHttp = axios.create({
            baseURL: 'https://linqrs.azurewebsites.net'
        });

        this.accessInstance = accessHttp;

        return accessHttp;
    }

    request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
        return this.http.request(config);
    }

    get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.get<T, R>(url, config);
    }

    post<T = any, R = AxiosResponse<T>>(
        url: string,
        data?: T,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.http.post<T, R>(url, data, config);
    }

    put<T = any, R = AxiosResponse<T>>(
        url: string,
        data?: T,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.http.put<T, R>(url, data, config);
    }

    delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.delete<T, R>(url, config);
    }

    // Handle global app errors
    // We can handle generic app errors depending on the status code
    private handleError(error: any) {
        const { status } = error;

        switch (status) {
            case StatusCode.InternalServerError: {
                // Handle InternalServerError
                break;
            }
            case StatusCode.Forbidden: {
                // Handle Forbidden
                break;
            }
            case StatusCode.Unauthorized: {
                // Handle Unauthorized
                break;
            }
            case StatusCode.TooManyRequests: {
                // Handle TooManyRequests
                break;
            }
            case StatusCode.BadRequest: {
                // Handle TooManyRequests
                break;
            }
        }

        return Promise.reject(error);
    }

    async refreshAccessToken(): Promise<AxiosResponse<any>> {
        const response = await this.accessHttp.get('/credentials');
        localStorage.setItem('token', JSON.stringify(response.data));
        return response;
    }

    getHoursDiff(date1: Date, date2: Date): number {

        const msBetweenDates = date1.getTime() - date2.getTime();
        let diffHrs = Math.floor((msBetweenDates % 86400000) / 3600000); // hours

        return diffHrs;
    }
}

export const http = new Http();


