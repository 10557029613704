import { HealthContext } from "./healthContext";
import css from './health.module.css';
import { InsuranceContext } from "../insurance/insuranceContext";
import { healthQuotoDto } from "./health.model";
import { InitialDateOfBirth } from "../utils/global.functions";
import { useContext, useEffect, useReducer, useState } from "react";
import { healthReducer } from "./healthReducer";
import RedirectToLandingPage from "../utils/RedirectToLandingPage";
import HealthName from "./HealthName";
import HealthBirth from "./HealthBirth";
import HealthGender from "./HealthGender";
import HealthNicotine from "./HealthNicotine";
import HealthEmail from "./HealthEmail";
import HealthAddress from "./HealthAddress";
import PhoneForm from "../utils/PhoneForm";
import PhoneAuth from "../utils/PhoneAuth";
import SubmitForm from "../utils/SubmitForm";
import InsuranceBundle from "../insurance/InsuranceBundle";
import HealthLanguages from "./HealthLanguages";
import HealthPeople from "./HealthPeople";
import HealthPreExisting from "./HealthPreExisting";
import { HEALTH_QUOTE_TYPE } from "../utils/GlobalConstants";

export default function HealthInitial (props: healthInitialProps)
{
    const totalIndex = 12;
    const context = useContext(InsuranceContext);

    const health: healthQuotoDto = {
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        PlaceId: '',
        FullAddress: '',
        Languages: [],
        BirthDate: InitialDateOfBirth(),
        Bundles: []
    }

    const [state, dispatch] = useReducer(healthReducer, health);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {

        dispatch({
            type: "QUOTE_TYPE",
            payload: HEALTH_QUOTE_TYPE
        })

    }, []);

    function NextClick() {

        setCurrentIndex(i => i + 1);
    }

    function BackClick() {
        setCurrentIndex(i => i - 1);
    }

    function displayWizard()
    {
        if (currentIndex === 0) {

            return (<>
                <HealthPeople onClick={NextClick} changeHeaderValue={props.changeHeaderValue} currentIndex={1} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 1) {
            return (<>
                <HealthName onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={2} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 2) {
            return (<>
                <HealthBirth onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={3} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 3) {
            return (<>
                <HealthGender onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={4} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 4) {
            return (<>
                <HealthNicotine onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={5} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 5) {
            return (<>
                <HealthPreExisting onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={6} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 6) {
            return (<>
                <HealthLanguages onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={7} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 7) {
            return (<>
                <HealthEmail onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={8} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 8) {
            return (<>
                <HealthAddress onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={8} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 9) {
            return (<>
                <PhoneForm onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} phoneNumber={state.PhoneNumber} v2={context.state.V2} updatePhoneNumber={(phoneNumber) => {
                    dispatch({
                        type: "HEALTH_PHONE_NUMBER",
                        payload: phoneNumber
                    })
                }} currentIndex={10} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 10) {
            return (<>
                <InsuranceBundle onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue}
                    updateBundle={() => {
                        state.Bundles = context.state.Bundles.map(({ quoteMenuTypeKey }) => quoteMenuTypeKey);
                    }} currentIndex={11} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 11) {
            return (<>
                <SubmitForm onClick={NextClick}
                    changeHeaderValue={props.changeHeaderValue}
                    title="You're seconds away from your health quotes!"
                    firstName={state.FirstName}
                    quoteType={state.QuoteType}
                    quoteData={JSON.stringify(state)} />
            </>)
        }
        else {
            return (
                <RedirectToLandingPage />
            )
        }
    }

    return (
        <div style={css}>
            <div className={css.divCenter}>
                <HealthContext.Provider value={{ state, dispatch }}>
                    {
                        displayWizard()
                    }
                </HealthContext.Provider>
            </div>
        </div>
    )
}

interface healthInitialProps{
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}