export default function Footer() {

    const anchorStyle = {
        textDecoration: 'none',
        color: "black",
     }

    return (
        <>
            &copy;&nbsp;{new Date().getFullYear()}&nbsp;<a href="https://www.linqrs.com" target="_blank" style={anchorStyle}>Linqrs</a>
        </>
    )
}