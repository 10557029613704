
import React, { createContext } from "react";
import { InitialDateOfBirth } from "../utils/global.functions";
import { autoQuotoDto } from "./auto.model";

export const AutoContext = createContext<{
    state: autoQuotoDto;
    dispatch: React.Dispatch<any>;
  }>({
    state: {
      FirstName: '',
      LastName: '',
      Violations: [],
      EmailAddress: '',
      PlaceId: '',
      FullAddress: '',
      Languages: [],
      Bundles: [],
      VehiclesWeb:[],
      BirthDate: InitialDateOfBirth()
    },
    dispatch: () => null
  });