import { useContext, useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { WEIGHT_ARRAY } from "../utils/GlobalConstants";
import Loading from "../utils/Loading";
import css from './term.module.css';
import { TermContext } from "./termContext";


export default function TermHeight(props: termHeightProps) {
    const { state, dispatch } = useContext(TermContext);
    const [weightArray, setWeightArrays] = useState<string[]>(WEIGHT_ARRAY);

    useEffect(() => {

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetWeight(weight: string) {

        dispatch({
            type: "TERM_WEIGHT",
            payload: weight
        });

        props.onClick();
    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>What is your current weight?</div>
            </div>
            {
                weightArray && weightArray.length > 0 ? (<div className={css.container}>
                    {
                        weightArray.map((weight) =>
                            <div className={(weight === state.Weight && (state.Weight !== undefined && state.Weight))
                                ? 'mb-3 ' + css.containerUnSelected : css.containerSelected + ' mb-3'} key={weight} onClick={() => SetWeight(weight)}>
                                {weight}
                            </div>
                        )
                    }
                </div>) :
                    <Loading />

            }
        </>
    )
}

interface termHeightProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}