import css from './business.module.css';
import Button from '../utils/Button';
import { FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import NameField from '../utils/NameField';
import { BusinessContext } from './businessContext';

export default function BusinessName(props: businessNameProps) {

    const { state, dispatch } = useContext(BusinessContext);
    const [businessName, setBusinessName] = useState(state.BusinessName);

    const businessNameSchema = Yup.object().shape({
        businessName: Yup.string().trim().min(1, 'Business Name too Short!').max(120, 'Business Name too Long!').required('Business Name Required'),
    });

    useEffect(() => {
        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);
    }, []);

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>What is the name of your business?</div>
            </div>
            <Formik
                initialValues={{
                    businessName: businessName,
                }}
                validationSchema={businessNameSchema}
                onSubmit={values => {

                    dispatch({
                        type: "BUSINESS_NAME",
                        payload: {
                            BusinessName: values.businessName,
                        }
                    })
                    props.onClick();
                }}
            >
                <Form>
                    <NameField name='businessName' placeholder='Type here ...' IsTrim={true} />
                    <div className={css.divNext}>
                        <Button type="submit" className='btn btn-primary' >
                            <div className="clearfix">
                                <div style={{ float: "left" }}>Next</div>
                                <div style={{ float: "right" }}><FaArrowRight /></div>
                            </div>
                        </Button>
                    </div>
                </Form>
            </Formik>
        </>
    )
}

interface businessNameProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}