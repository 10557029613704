import css from './phoneForm.module.css';
import Button from '../utils/Button';
import { FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input';
import { ValidatePhoneForE164 } from '../utils/global.functions';
import Loading from '../utils/Loading';
import { E164Number } from 'libphonenumber-js';

export default function PhoneForm(props: phoneFormProps) {
    const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber);
    const [errors, setErrors] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    async function SetPhoneNumber() {

        if (phoneNumber === null || phoneNumber === undefined || !ValidatePhoneForE164(phoneNumber)) {
            setErrors('Phone number is required');
            return;
        }

          props.updatePhoneNumber(phoneNumber);
          props.onClick();
    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-1'>
                <div className={css.divCenterText}>What is your phone number?</div>
            </div>
            <div className="mb-3">
                <div className={css.divCenterTextSmall}>
                    Linqrs won't spam you with unwanted calls
                </div>
            </div>
            {
                !loading ? (
                    <div className='mb-3'>
                        <PhoneInput placeholder='Enter phone number' country="US" value={phoneNumber} onChange={(value) => {
                            setErrors('');

                            if (value !== undefined) {
                                setPhoneNumber(value);
                            }
                        }} style={{ width: "100%", height: "3.125rem", paddingLeft: "5px" }} maxLength="14" />
                        {errors ? (
                            <div style={{ color: "red", fontSize: "0.875rem" }}>{errors}</div>
                        ) : null}
                        <div className={css.divConfirm}>
                            By clicking "Next" you agree to Linqrs <a href='https://www.linqrs.com/privacy-policy' target="_blank">Privacy Policy</a> and <a href='https://www.linqrs.com/terms-and-conditions' target="_blank">Terms of Use</a> (including arbitration and class waiver). Consent is not a condition of any purchase.
                        </div>
                    </div>
                ) :
                    <div style={{ textAlign: 'center' }}>
                        <Loading />
                    </div>
            }
            <div className={css.divNext}>
                <Button type="button" className='btn btn-primary' onclick={SetPhoneNumber}>
                    <div className="clearfix">
                        <div style={{ float: "left" }}>Next</div>
                        <div style={{ float: "right" }}><FaArrowRight /></div>
                    </div>
                </Button>
            </div>
        </>
    )
}

interface phoneFormProps {
    phoneNumber: E164Number;
    updatePhoneNumber(phoneNumber: E164Number): void;
    v2: boolean;
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}