
import { BusinessRiskDTO, TypeOfPolicyDTO } from "../business/business.model";
import { DwellingClaimDTO } from "../homeowners/homeowners.model";
import { http } from "./Http";
import { AutomobileDTO, GenderDTO, InsuranceAmountDTO, InsuranceProviderDTO, LanguageDTO, NumberOfCarsDTO, NumberOfDriversDTO, NumberOfPeopleDTO, QuoteTypeDTO, StateDTO, ViolationDTO } from "./services.model";

export const fetchStates = async (): Promise<StateDTO[] | any> => {
  return await http.get<StateDTO[]>("/lookup/states");
};

export const fetchInsuranceProviders = async (): Promise<InsuranceProviderDTO[] | any> => {
  return await http.get<InsuranceProviderDTO[]>("/lookup/insuranceproviders");
};

export const fetchQuoteTypes = async (): Promise<QuoteTypeDTO[] | any> => {
  return await http.get<QuoteTypeDTO[]>("/lookup/quoteTypes");
};

export const fetchNumberOfCars = async (): Promise<NumberOfCarsDTO[] | any> => {
  return await http.get<NumberOfCarsDTO[]>("/lookup/number-of-cars");
};

export const fetchNumberOfDrivers = async (): Promise<NumberOfDriversDTO[] | any> => {
  return await http.get<NumberOfDriversDTO[]>("/lookup/number-of-drivers");
};

export const fetchNumberOfEmployees = async (): Promise<NumberOfPeopleDTO[] | any> => {
  return await http.get<NumberOfPeopleDTO[]>("/lookup/number-of-employees");
};

export const fetchTypeOfPolicies = async (): Promise<TypeOfPolicyDTO[] | any> => {
  return await http.get<TypeOfPolicyDTO[]>("/lookup/type-of-policies");
};

export const fetchBusinessRisks = async (): Promise<BusinessRiskDTO[] | any> => {
  return await http.get<BusinessRiskDTO[]>("/lookup/business-risk");
};

export const fetchGenders = async (): Promise<GenderDTO[] | any> => {
  return await http.get<GenderDTO[]>("/lookup/genders");
};

export const fetchViolations = async (): Promise<ViolationDTO[] | any> => {
  return await http.get<ViolationDTO[]>("/lookup/violations");
};

export const fetchDwellingClaims = async (): Promise<DwellingClaimDTO[] | any> => {
  return await http.get<DwellingClaimDTO[]>("/lookup/dwelling-claims");
};

export const fetchLanguages = async (): Promise<LanguageDTO[] | any> => {
  return await http.get<LanguageDTO[]>("/lookup/languages");
};

export const fetchInsuranceAmounts = async (): Promise<InsuranceAmountDTO[] | any> => {
  return await http.get<InsuranceAmountDTO[]>("/lookup/insurance-amounts");
};

export const saveQuote = async (request: WebLeadRequest): Promise<WebLeadResponse | any> => {
  return await http.post<WebLeadRequest>("/weblead/inbound", request);
};

export const fetchSendPhone= async (phoneNumber: string): Promise<boolean | any> => {
  return await http.get<boolean>(`/phoneauth/send-phone-code?phoneNumber=${phoneNumber}`);
};

export const fetchVerifyPhone = async (phoneNumber: string, verificationCode: string): Promise<ValidatePhoneVerificationResponse | any> => {
  return await http.get<ValidatePhoneVerificationResponse>(`/phoneauth/verify-phone-code?phoneNumber=${phoneNumber}&verificationCode=${verificationCode}`);
};

export const fetchValidationPhone = async (phoneNumber: string): Promise<PhoneValidationResponse | any> => {
  return await http.get<PhoneValidationResponse>(`/phoneauth/phone-validation?phoneNumber=${phoneNumber}`);
};

export const fetchAutomobiles = async (): Promise<AutomobileDTO[] | any> => {
  return await http.get<AutomobileDTO[]>("/lookup/automobiles");
};

export const fetchAutomobileModels= async (id: number): Promise<boolean | any> => {
  return await http.get<boolean>(`/lookup/automobilemodels/${id}`);
};

export interface WebLeadRequest {
  quoteType: QuoteMenuTypeKey, 
  jsonData: string
}

export interface WebLeadResponse {
  errorMessage: string;
  successful: boolean;
}

export enum QuoteMenuTypeKey {
  Auto = 1000
}

export interface ValidatePhoneVerificationResponse{
  message: string;
  success: boolean;
}

export interface PhoneValidationResponse{
  errorMessage: string;
  successful: boolean;
}