import { useState } from "react";
import AutoComplete from "react-google-autocomplete";
import "./AddressCompleteField.css"

export default function AutoCompleteField(props: addressCompleteField) {
    const [country, setCountry] = useState("us");

    return (
        <AutoComplete apiKey={JSON.parse(localStorage.getItem('token') || '{}').apiKey}
            options={{
                types: ["geocode", "establishment"],
                componentRestrictions: { country },
            }}
            onPlaceSelected={(place) => {

                for(let i=0; i< place.address_components.length; i++)
                {
                    if(place.address_components[i].types !== null || place.address_components[i].types !== undefined)
                    {
                        var types = place.address_components[i].types;
                        
                        if(types.indexOf('postal_code') > -1 === true)
                        {
                            props.onPlaceSelected(place.place_id, place.formatted_address);
                            return;
                        }
                    }
                }

                props.onPlaceSelected('', '');
            }}
            onChange={(value) => {
                props.onChange();
            }}
            defaultValue={props.fullAddress}
            placeholder={props.placeholder}
            className='address-field' />
    )
}

interface addressCompleteField {
    onPlaceSelected(placeId: string, formatted_address: string): void;
    onChange(): void;
    fullAddress: string;
    placeholder: string
}