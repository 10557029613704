import { BusinessContext } from "./businessContext";
import css from './business.module.css';
import { InsuranceContext } from "../insurance/insuranceContext";
import { businessQuotoDto } from "./business.model";
import { useContext, useEffect, useReducer, useState } from "react";
import { businessReducer } from "./businessReducer";
import RedirectToLandingPage from "../utils/RedirectToLandingPage";
import BusinessName from "./BusinessName";
import BusinessAddress from "./BusinessAddress";
import PhoneForm from "../utils/PhoneForm";
import SubmitForm from "../utils/SubmitForm";
import BusinessPeople from "./BusinessPeople";
import BusinessRisk from "./BusinessRisk";
import BusinessPolicy from "./BusinessPolicy";
import BusinessCommercialAuto from "./BusinessCommercialAuto";
import BusinessFullName from "./BusinessFullName";
import BusinessFunction from "./BusinessFunction";
import BusinessInsured from "./BusinessInsured";
import BusinessClaimsLast3Years from "./BusinessClaimsPast3Years";
import BusinessEmail from "./BusinessEmail";
import InsuranceBundle from "../insurance/InsuranceBundle";
import { BUSINESS_QUOTE_TYPE } from "../utils/GlobalConstants";

export default function BusinessInitial (props: businessInitialProps)
{
    const totalIndex = 14;
    const context = useContext(InsuranceContext);

    const business: businessQuotoDto = {
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        PlaceId: '',
        TypeOfPolicy: '',
        BusinessRisk: '',
        FullAddress: '',
        AmountOfEmployees: '',
        BusinessName: '',
        BusinessFunction: '',
        Bundles:[]
    }

    const [state, dispatch] = useReducer(businessReducer, business);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {

        dispatch({
            type: "QUOTE_TYPE",
            payload: BUSINESS_QUOTE_TYPE
        })

    }, []);

    function NextClick() {

        setCurrentIndex(i => i + 1);
    }

    function BackClick() {
        setCurrentIndex(i => i - 1);
    }

    function displayWizard()
    {
        if (currentIndex === 0) {

            return (<>
                <BusinessPolicy onClick={NextClick} changeHeaderValue={props.changeHeaderValue} currentIndex={1} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 1) {
            return (<>
                <BusinessRisk onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={2} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 2) {
            return (<>
                <BusinessPeople onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={3} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 3) {
            return (<>
                <BusinessCommercialAuto onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={4} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 4) {
            return (<>
                <BusinessName onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={5} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 5) {
            return (<>
                <BusinessFullName onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={6} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 6) {
            return (<>
                <BusinessFunction onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={7} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 7) {
            return (<>
                <BusinessAddress onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={8} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 8) {
            return (<>
                <BusinessInsured onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={9} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 9) {
            return (<>
                <BusinessClaimsLast3Years onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={10} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 10) {
            return (<>
                <BusinessEmail onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={11} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 11) {
            return (<>
                <PhoneForm onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} phoneNumber={state.PhoneNumber} v2={context.state.V2} updatePhoneNumber={(phoneNumber) => {
                    dispatch({
                        type: "BUSINESS_PHONE_NUMBER",
                        payload: phoneNumber
                    })
                }} currentIndex={12} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 12) {
            return (<>
                <InsuranceBundle onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} updateBundle={() => {
                    state.Bundles = context.state.Bundles.map(({ quoteMenuTypeKey }) => quoteMenuTypeKey);
                }} currentIndex={13} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 13) {
            return (<>
                <SubmitForm onClick={NextClick}
                    changeHeaderValue={props.changeHeaderValue}
                    title="You're seconds away from your business quotes!"
                    firstName={state.FirstName}
                    quoteType={state.QuoteType}
                    quoteData={JSON.stringify(state)} />
            </>)
        }
        else {
            return (
                <RedirectToLandingPage />
            )
        }
    }

    return (
        <div style={css}>
            <div className={css.divCenter}>
                <BusinessContext.Provider value={{ state, dispatch }}>
                    {
                        displayWizard()
                    }
                </BusinessContext.Provider>
            </div>
        </div>
    )
}

interface businessInitialProps{
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}