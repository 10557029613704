import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { FaAngleLeft, FaArrowRight, FaHome } from "react-icons/fa";
import { fetchDwellingClaims, fetchViolations } from "../services/LinqrsApi";
import Button from "../utils/Button";
import Loading from "../utils/Loading";
import css from './homeowners.module.css';
import { DwellingClaimDTO } from "./homeowners.model";
import { HomeownersContext } from "./homeownersContext";
import { DWELLING_CLAIMS } from "../utils/GlobalConstants";

export default function HomeownersClaim(props: homeownersClaimProps) {
    const { state, dispatch } = useContext(HomeownersContext);
    const [dwellingClaims, setDwellingClaim] = useState<DwellingClaimDTO[]>([]);

    useEffect(() => {

        const fetchDwellingClaimsDataAsync = async () => {
            var dwellingClaimsSessionData = sessionStorage.getItem(DWELLING_CLAIMS);

            if (dwellingClaimsSessionData === null) {
                const response: AxiosResponse<DwellingClaimDTO[]> = await fetchDwellingClaims();
                var data = response.data;
                sessionStorage.setItem(DWELLING_CLAIMS, JSON.stringify(data));
                setDwellingClaim(data);
            }
            else {
                setDwellingClaim(JSON.parse(dwellingClaimsSessionData));
            }
        }

        fetchDwellingClaimsDataAsync();
        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetDwellingClaims(dwellingClaim: DwellingClaimDTO) {

        if (state.DwellingClaims.findIndex(d => d.dwellingClaimId === dwellingClaim.dwellingClaimId) > -1) {

            const currentIndex = state.DwellingClaims.findIndex(d => d.dwellingClaimId === dwellingClaim.dwellingClaimId);
            state.DwellingClaims[currentIndex].isSelected = !state.DwellingClaims[currentIndex].isSelected;

            dispatch({
                type: "HOMEOWNER_CLAIMS",
                payload: [...state.DwellingClaims]
            })
        }
        else {
            dwellingClaim.isSelected = !dwellingClaim.isSelected;

            dispatch({
                type: "HOMEOWNER_CLAIMS",
                payload: [...state.DwellingClaims, dwellingClaim]
            })
        }

    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>To expedite and get you to the right agent, please
                    list all claims in the last 3 years. If none, hit next</div>
            </div>
            <div className="mb-3">
                <div className={css.divCenterTextSmall}>
                    You can save up to 20% when you bundle your policy with your car insurance!
                </div>
            </div>
            {
                dwellingClaims && dwellingClaims.length > 0 ? (
                    <div className={css.container}>
                        {
                            dwellingClaims.map((dwellingClaim) =>
                                <div className={state.DwellingClaims && state.DwellingClaims.findIndex(d => d.dwellingClaimId === dwellingClaim.dwellingClaimId && d.isSelected) > -1 ? 'mb-3 ' + css.containerUnSelected : css.containerSelected + ' mb-3'} key={dwellingClaim.dwellingClaimId} onClick={() => SetDwellingClaims(dwellingClaim)}>
                                    {dwellingClaim.description}
                                </div>
                            )
                        }
                    </div>
                ) : <Loading />
            }
            <div className={css.divNext}>
                <Button type="button" className='btn btn-primary' onclick={props.onClick}>
                    <div className="clearfix">
                        <div style={{ float: "left" }}>Next</div>
                        <div style={{ float: "right" }}><FaArrowRight /></div>
                    </div>
                </Button>
            </div>
        </>
    )
}

interface homeownersClaimProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}