import { Link, Navigate, NavLink } from "react-router-dom";
import css from './business.module.css';
import { useContext, useEffect, useState } from "react";
import Loading from "../utils/Loading";
import { AxiosResponse } from "axios";
import { fetchNumberOfDrivers, fetchNumberOfEmployees } from "../services/LinqrsApi";
import { FaAngleLeft } from "react-icons/fa";
import { BUSINESS_NUMBER_OF_PEOPLES } from "../utils/GlobalConstants";
import { InsuranceContext } from "../insurance/insuranceContext";
import { BUSINESS_EMPLOYEE_ARRAY } from "../utils/GlobalConstants";
import { BusinessContext } from "./businessContext";

export default function BusinessPeople(props: businessPeopleProps) {

    const { state, dispatch } = useContext(BusinessContext);
    const context = useContext(InsuranceContext);
    const [businessEmployeeArray, setBusinessEmployeeArray] = useState<string[]>(BUSINESS_EMPLOYEE_ARRAY);

    let titleDescr = `How many employees do you have?`;

    useEffect(() => {

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetNumberOfPeoples(numberOfPeople: string) {

        dispatch({
            type: "BUSINESS_PEOPLE",
            payload: numberOfPeople
        })

        props.onClick();
    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>{titleDescr}</div>
            </div>
            {
                businessEmployeeArray && businessEmployeeArray.length > 0 ? (<div className={css.container}>
                    {
                        businessEmployeeArray.map((people) =>
                            <div className={people === state.AmountOfEmployees ? 'mb-3 ' + css.containerUnSelected : css.containerSelected + ' mb-3'} key={people} onClick={() => SetNumberOfPeoples(people)}>
                                {people}
                            </div>
                        )
                    }
                </div>) :
                  <Loading />
            }
        </>
    )
}

interface businessPeopleProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}