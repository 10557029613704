import { useContext, useEffect, useReducer, useState } from "react";
import { homeownerQuotoDto } from "../homeowners/homeowners.model";
import HomeownersAddress from "../homeowners/HomeownersAddress";
import HomeownersBirth from "../homeowners/HomeownersBirth";
import HomeownersClaim from "../homeowners/HomeownersClaim";
import { HomeownersContext } from "../homeowners/homeownersContext";
import HomeownersEmail from "../homeowners/HomeownersEmail";
import HomeownersLanguages from "../homeowners/HomeownersLanguages";
import HomeownersName from "../homeowners/HomeownersName";
import { homeownerReducer } from "../homeowners/homeownersReducer";
import InsuranceBundle from "../insurance/InsuranceBundle";
import { InsuranceContext } from "../insurance/insuranceContext";
import { InitialDateOfBirth } from "../utils/global.functions";
import { RENTERS_QUOTE_TYPE } from "../utils/GlobalConstants";
import PhoneForm from "../utils/PhoneForm";
import RedirectToLandingPage from "../utils/RedirectToLandingPage";
import SubmitForm from "../utils/SubmitForm";
import css from './renters.module.css';

export default function RentersInitial(props: rentersInitialProps) {

    const totalIndex = 9;
    const context = useContext(InsuranceContext);

    const homeowner: homeownerQuotoDto = {
        FirstName: '',
        LastName: '',
        DwellingClaims: [],
        EmailAddress: '',
        PlaceId: '',
        FullAddress: '',
        Languages: [],
        BirthDate: InitialDateOfBirth(),
        Bundles: []
    }

    const [state, dispatch] = useReducer(homeownerReducer, homeowner);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {

        dispatch({
            type: "QUOTE_TYPE",
            payload: RENTERS_QUOTE_TYPE
        })

    }, []);

    function NextClick() {

        setCurrentIndex(i => i + 1);
    }

    function BackClick() {
        setCurrentIndex(i => i - 1);
    }

    function displayWizard() {
        if (currentIndex === 0) {

            return (<>
                <HomeownersName onClick={NextClick} changeHeaderValue={props.changeHeaderValue} currentIndex={1} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 1) {
            return (<>
                <HomeownersBirth onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={2} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 2) {
            return (<>
                <HomeownersClaim onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={3} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 3) {
            return (<>
                <HomeownersLanguages onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={4} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 4) {
            return (<>
                <HomeownersEmail onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={5} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 5) {
            return (<>
                <HomeownersAddress onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={6} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 6) {
            return (<>
                <PhoneForm onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} phoneNumber={state.PhoneNumber} v2={context.state.V2} updatePhoneNumber={(phoneNumber) => {
                    dispatch({
                        type: "HOMEOWNER_PHONE_NUMBER",
                        payload: phoneNumber
                    })
                }} currentIndex={7} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 7) {
            return (<>
                <InsuranceBundle onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} updateBundle={() => {
                    state.Bundles = context.state.Bundles.map(({ quoteMenuTypeKey }) => quoteMenuTypeKey);
                }} currentIndex={8} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 8) {
            return (<>
                <SubmitForm onClick={NextClick}
                    changeHeaderValue={props.changeHeaderValue}
                    title="You're seconds away from your renters quotes!"
                    firstName={state.FirstName}
                    quoteType={state.QuoteType}
                    quoteData={JSON.stringify(state)} />
            </>)
        }

        else {
            return (
                <RedirectToLandingPage />
            )
        }
    }

    return (
        <div style={css}>
            <div className={css.divCenter}>
                <HomeownersContext.Provider value={{ state, dispatch }}>
                    {
                        displayWizard()
                    }
                </HomeownersContext.Provider>
            </div>
        </div>
    )
}

interface rentersInitialProps {
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}