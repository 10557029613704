import { ErrorMessage, Field, useFormikContext } from "formik";
import "./NameField.css"

export default function NameField({ IsTrim = false, ...props}: nameFieldProps) {

    const { errors, touched, setFieldValue } = useFormikContext<any>();

    return (<div className='mb-3'>
        <Field name={props.name} id={props.name} placeholder={props.placeholder}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(props.name, !IsTrim ? e.target.value.trim() : e.target.value);
            }} className="name-field" />
        {errors[props.name] && touched[props.name] ? (
            <div className="name-field-div"><ErrorMessage name={props.name} /></div>
        ) : null}
    </div>)
}

interface nameFieldProps {
    name: string;
    placeholder: string;
    IsTrim?: boolean;
}