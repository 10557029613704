import { Link, Navigate } from "react-router-dom";
import css from './term.module.css';
import { useContext, useEffect, useState } from "react";
import Loading from "../utils/Loading";
import { FaAngleLeft } from "react-icons/fa";
import { TermContext } from "./termContext";
import { YES_OR_NO_ARRAY } from "../utils/GlobalConstants";


export default function TermNicotine(props: termNicotineProps) {

    const { state, dispatch } = useContext(TermContext);
    const [yesOrNoArray, setYesOrNoArrays] = useState<string[]>(YES_OR_NO_ARRAY);

    useEffect(() => {
        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetUseNicotine(yesOrNo: string) {

        dispatch({
            type: "TERM_NICOTINE",
            payload: yesOrNo === 'Yes'
        })

        props.onClick();
    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>To expedite and get you to the right agent, do you use nicotine products?</div>
            </div>
            {
                yesOrNoArray && yesOrNoArray.length > 0 ? (<div className={css.container}>
                    {
                        yesOrNoArray.map((yesOrNo) =>
                            <div className={(yesOrNo === 'Yes' && (state.UseNicotine !== undefined && state.UseNicotine)) || (yesOrNo === 'No' && (state.UseNicotine !== undefined && !state.UseNicotine)) ? 'mb-3 ' + css.container50UnSelected : css.container50Selected + ' mb-3'} key={yesOrNo} onClick={() => SetUseNicotine(yesOrNo)}>
                                {yesOrNo}
                            </div>
                        )
                    }
                </div>) :
                 <Loading />
            }
        </>
    )
}

interface termNicotineProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}