import { InsuranceQuoteDTO } from "./insurance.model";

export const insuranceReducer = (state: InsuranceQuoteDTO, action: any) => {
  switch (action.type) {
    case "QUOTE_TYPE":
      return {
        ...state,
        QuoteType: action.payload,
        Bundles: []
      };
    case "QUOTE_BUNDLES":
      return {
        ...state,
        Bundles: action.payload
      };
    case "QUOTE_TYPE_V2":
      return {
        ...state,
        QuoteType: action.payload.quoteType,
        V2: action.payload.v2,
        Bundles: []
      };
    default:
      return state;
  }
}