import { useNavigate } from "react-router-dom";
import css from './insurance.module.css';
import { useContext, useEffect, useState } from "react";
import Loading from "../utils/Loading";
import { InsuranceContext } from "./insuranceContext";
import { QuoteTypeDTO } from "../services/services.model";
import { FaBuilding, FaCarAlt, FaDoorOpen, FaHome, FaHospitalUser, FaHotel, FaPrayingHands, FaUser, FaUserFriends, FaUsers } from "react-icons/fa";
import { QUOTES_ARRAY } from "../utils/GlobalConstants";


export default function InsuranceInitial(props: insuranceInitialProps) {

    let navigate = useNavigate();
    const { dispatch } = useContext(InsuranceContext);
    const [quoteTypes, setQuoteTypes] = useState<QuoteTypeDTO[]>(QUOTES_ARRAY);

    useEffect(() => {

        props.changeHeaderValue(true, 0, '');

    }, []);

    function SetInsurance(quoteType: QuoteTypeDTO) {

        dispatch({
            type: "QUOTE_TYPE",
            payload: quoteType
        });

        navigate(`/signup/compare-${quoteType.descriptionAbbr.toLowerCase()}-insurance-quotes`);
    }

    return (
        <div style={css}>
            <div className={css.divCenter}>
                <div className='mb-2'>
                    <div className={css.divCenter30Text}>FAST Direct access to your area's top local insurance agents providing excellent service</div>
                </div>
                <div className='mb-3'>
                    <div className={css.divCenterTextSmall}>We match you with local insurance agents that will shop over 100+ insurance carriers to find the insurance policy that's right for you.</div>
                </div>
                <div className='mb-3'>
                    <div className={css.divCenter18Text}>Select a Product</div>
                </div>
                {
                    quoteTypes && quoteTypes.length > 0 ? (<div className={css.container}>
                        {
                            quoteTypes.map((quoteType) =>
                                <div className={css.containerSelected} key={quoteType.description} onClick={() => SetInsurance(quoteType)}>
                                    {
                                        displayQuoteType(quoteType.description)
                                    }

                                </div>
                            )
                        }
                    </div>) : <Loading />
                }
                <div className='mt-3 mb-3'>
                    <div className={css.divCenterTextSmall}>No need to shop around for insurance. Get competitive insurance quotes from insurance agents who want your insurance business.</div>
                </div>
                <div>
                    <div className={css.divCenter30Text}>Get and compare insurance quotes from local insurance agents in 5 minutes or less</div>
                </div>
            </div>
        </div>
    )

    function displayQuoteType(description: string) {
        if (description === 'Auto') {
            return (<div>
                <div>
                    <FaCarAlt size={'2rem'} />
                </div>
                <span className={css.divSpan}>Car</span>
            </div>)
        }
        else if (description === 'Homeowners') {
            return (<div>
                <div>
                    <FaHome size={'2rem'} />
                </div>
                <span className={css.divSpan}>Home</span>
            </div>)
        }
        else if (description === 'Renters') {
            return (<div>
                <div>
                    <FaBuilding size={'2rem'} />
                </div>
                <span className={css.divSpan}>{description}</span>
            </div>)
        }
        else if (description === 'Whole Life') {
            return (<div>
                <div>
                    <FaUserFriends size={'2rem'} />
                </div>
                <span className={css.divSpan}>{description}</span>
            </div>)
        }
        else if (description === 'Universal Life') {
            return (<div>
                <div>
                    <FaUser size={'2rem'} />
                </div>
                <span className={css.divSpan}>{description}</span>
            </div>)
        }
        else if (description === 'Term Life') {
            return (<div>
                <div>
                    <FaUsers size={'2rem'} />
                </div>
                <span className={css.divSpan}>{description}</span>
            </div>)
        }
        else if (description === 'Health') {
            return (<div>
                <div>
                    <FaPrayingHands size={'2rem'} />
                </div>
                <span className={css.divSpan}>{description}</span>
            </div>)
        }
        else if (description === 'Medicare') {
            return (<div>
                <div>
                    <FaHospitalUser size={'2rem'} />
                </div>
                <span className={css.divSpan}>{description}</span>
            </div>)
        }
        else if (description === 'Condo') {
            return (<div>
                <div>
                    <FaHotel size={'2rem'} />
                </div>
                <span className={css.divSpan}>{description}</span>
            </div>)
        }
        else if (description === 'Business') {
            return (<div>
                <div>
                    <FaDoorOpen size={'2rem'} />
                </div>
                <span className={css.divSpan}>{description}</span>
            </div>)
        }
    }
}

interface insuranceInitialProps {
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}

