import css from './auto.module.css';
import Button from '../utils/Button';
import { FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import { AutoContext } from './autoContext';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

export default function AutoDriver(props: autoDriverProps) {

    const { state, dispatch } = useContext(AutoContext);
    const [firstName, setFirstName] = useState(state.FirstName);
    const [lastName, setLastName] = useState(state.LastName);

    const driverSchema = Yup.object().shape({
        firstName: Yup.string().trim().min(2, 'First Name too Short!').max(50, 'First Name too Long!').required('First Name Required'),
        lastName: Yup.string().trim().min(2, 'Last Name tooo Short!').max(50, 'Last Name too Long!').required('Last Name Required')
    });

    useEffect(() => {

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>What is your name?</div>
            </div>
            <Formik
                initialValues={{
                    firstName: firstName,
                    lastName: lastName
                }}
                validationSchema={driverSchema}
                onSubmit={values => {

                    dispatch({
                        type: "AUTO_DRIVER",
                        payload: {
                            FirstName: values.firstName,
                            LastName: values.lastName
                        }
                    })

                    props.onClick();
                }}
            >
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                        <div className='mb-3'>
                            <Field name="firstName" placeholder="First Name" style={{ width: "100%", height: "3.125rem", paddingLeft: "5px" }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                                    setFieldValue('firstName', e.target.value.trim());
                                }} />
                            {errors.firstName && touched.firstName ? (
                                <div style={{ color: "red", fontSize: "0.875rem" }}>{errors.firstName}</div>
                            ) : null}
                        </div>

                        <div className='mb-3'>
                            <Field name="lastName" placeholder="Last Name" style={{ width: "100%", height: "3.125rem", paddingLeft: "5px" }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                                setFieldValue('lastName', e.target.value.trim());
                            }} />
                            {errors.lastName && touched.lastName ? (
                                <div style={{ color: "red", fontSize: "0.875rem" }}>{errors.lastName}</div>
                            ) : null}
                        </div>
                        <div className={css.divNext}>
                            <Button type="submit" className='btn btn-primary' >
                                <div className="clearfix">
                                    <div style={{ float: "left" }}>Next</div>
                                    <div style={{ float: "right" }}><FaArrowRight /></div>
                                </div>
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

interface autoDriverProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}