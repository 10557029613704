import { healthQuotoDto } from "./health.model";



export const healthReducer = (state: healthQuotoDto, action: any) => {
    switch (action.type) {

        case "QUOTE_TYPE":
            return {
                ...state,
                QuoteType: action.payload
            };

        case "HEALTH_NAME":
            return {
                ...state,
                FirstName: action.payload.FirstName,
                LastName: action.payload.LastName,
            };

        case "HEALTH_DATE_OF_BIRTH":
            return {
                ...state,
                BirthDate: action.payload
            };

        case "HEALTH_AMOUNT":
            return {
                ...state,
                InsuranceAmount: action.payload
            };

        case "HEALTH_PEOPLE":
            return {
                ...state,
                NumberOfPeople: action.payload
            };

        case "HEALTH_NICOTINE":
            return {
                ...state,
                UseNicotine: action.payload
            };

        case "HEALTH_PREEXISTING_CONDITIONS":
            return {
                ...state,
                PreExistingConditions: action.payload
            };

        case "HEALTH_GENDER":
            return {
                ...state,
                Gender: action.payload
            };

        case "HEALTH_EMAIL_ADDRESS":
            return {
                ...state,
                EmailAddress: action.payload
            };
        case "HEALTH_ADDRESS":
            return {
                ...state,
                PlaceId: action.payload.placeId,
                FullAddress: action.payload.fullAddress
            };

        case "HEALTH_PHONE_NUMBER":
            return {
                ...state,
                PhoneNumber: action.payload
            };

        case "HEALTH_LANGUAGES":
            return {
                ...state,
                Languages: action.payload
            };
        case "HEALTH_BUNDLES":
            return {
                ...state,
                Bundles: action.payload
            };
        default:
            return state;
    }
}