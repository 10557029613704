import { QuoteTypeDTO } from "../services/services.model";

export const LINQRS_WEBSITE = 'https://www.linqrs.com'
export const QUOTE_TYPE = 'quote-type';
export const NUMBER_OF_CARS = 'number-of-cars';
export const NUMBER_OF_AUTOMOBILES = 'number-of-automobiles'
export const NUMBER_OF_DRIVERS = 'number-of-drivers';
export const INSURANCE_PROVIDERS = 'insurance-providers';
export const GENDER = 'gender';
export const LANGUAGES = 'languages';
export const VIOLATIONS = 'violations';
export const DWELLING_CLAIMS = 'dwelling-claims';
export const INSURANCE_AMOUNTS = 'insurance-amounts';
export const NUMBER_OF_PEOPLES = 'number-of-peoples'
export const RISK = 'risk'
export const TYPE_OF_POLICY = 'type-of-policy'
export const BUSINESS_NUMBER_OF_PEOPLES = 'business-number-of-peoples'
export const YES_OR_NO_ARRAY = ['Yes', 'No'];
export const MARITAL_STATUS_ARRAY = ['Married', 'Single'];
export const TYPE_OF_POLICY_ARRAY = ['Liability Only', 'Full Business Coverage'];
export const RISK_ARRAY = ['Low Risk', 'Medium', 'High Risk'];
export const BUSINESS_EMPLOYEE_ARRAY = ['1-5', '6-10', '11-20', '21-50', '51-100', '101+'];
export const AUTO_QUOTE_TYPE = 1000;
export const HOMEOWNERS_QUOTE_TYPE = 3000;
export const RENTERS_QUOTE_TYPE = 4000;
export const LIFE_QUOTE_TYPE = 10000;
export const TERM_QUOTE_TYPE = 41000;
export const HEALTH_QUOTE_TYPE = 20000;
export const BUSINESS_QUOTE_TYPE = 23000;
export const HEIGHT_ARRAY = ['4\'5 or less', '4\'6 to 4\'11', '5\'0 to 5\'5', '5\'6 to 5\'11', '6\'0 to 6\'5', '6\'6 or taller'];
export const WEIGHT_ARRAY = ['74 lbs or less', '75 lbs to 99 lbs', '100 lbs to 119 lbs', '120 lbs to 139 lbs', '140 lbs to 170 lbs', '171 lbs to 199 lbs', '200 lbs to 229 lbs', '230 lbs to 249 lbs', '250 lbs to 299 lbs', '300 lbs or more'];
export const QUOTES_ARRAY: QuoteTypeDTO[] = [{
    quoteMenuTypeKey: AUTO_QUOTE_TYPE,
    description: 'Auto',
    descriptionAbbr: 'Auto',
    isSelected: false,
    isWebEnabled: true,
    wizardEnabled: true,
}, {
    quoteMenuTypeKey: HOMEOWNERS_QUOTE_TYPE,
    description: 'Homeowners',
    descriptionAbbr: 'Homeowners',
    isSelected: false,
    isWebEnabled: true,
    wizardEnabled: true,
}, {
    quoteMenuTypeKey: RENTERS_QUOTE_TYPE,
    description: 'Renters',
    descriptionAbbr: 'Renters',
    isSelected: false,
    isWebEnabled: true,
    wizardEnabled: true,
}, {
    quoteMenuTypeKey: RENTERS_QUOTE_TYPE,
    description: 'Condo',
    descriptionAbbr: 'Condo',
    isSelected: false,
    isWebEnabled: true,
    wizardEnabled: true,
}, {
    quoteMenuTypeKey: LIFE_QUOTE_TYPE,
    description: 'Whole Life',
    descriptionAbbr: 'Life',
    isSelected: false,
    isWebEnabled: true,
    wizardEnabled: true,
},
{
    quoteMenuTypeKey: TERM_QUOTE_TYPE,
    description: 'Term Life',
    descriptionAbbr: 'Term',
    isSelected: false,
    isWebEnabled: true,
    wizardEnabled: true,
}, {
    quoteMenuTypeKey: HEALTH_QUOTE_TYPE,
    description: 'Health',
    descriptionAbbr: 'Health',
    isSelected: false,
    isWebEnabled: true,
    wizardEnabled: true,
}, {
    quoteMenuTypeKey: HEALTH_QUOTE_TYPE,
    description: 'Medicare',
    descriptionAbbr: 'Medicare',
    isSelected: false,
    isWebEnabled: true,
    wizardEnabled: true,
}, {
    quoteMenuTypeKey: BUSINESS_QUOTE_TYPE,
    description: 'Business',
    descriptionAbbr: 'Business',
    isSelected: false,
    isWebEnabled: true,
    wizardEnabled: true,
}];