import { Link, Navigate } from "react-router-dom";
import css from './health.module.css';
import { useContext, useEffect, useState } from "react";
import Loading from "../utils/Loading";
import { FaAngleLeft } from "react-icons/fa";
import { HealthContext } from "./healthContext";
import { YES_OR_NO_ARRAY } from "../utils/GlobalConstants";


export default function HealthPreExisting(props: healthPreExistingProps) {

    const { state, dispatch } = useContext(HealthContext);
    const [yesOrNoArray, setYesOrNoArrays] = useState<string[]>(YES_OR_NO_ARRAY);

    useEffect(() => {
        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetUsePreExisting(yesOrNo: string) {

        dispatch({
            type: "HEALTH_PREEXISTING_CONDITIONS",
            payload: yesOrNo === 'Yes'
        })

        props.onClick();
    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>To expedite and get you to the right agent, do you have any pre-existing conditions?</div>
            </div>
            {
                yesOrNoArray && yesOrNoArray.length > 0 ? (<div className={css.container}>
                    {
                        yesOrNoArray.map((yesOrNo) =>
                            <div className={(yesOrNo === 'Yes' && (state.PreExistingConditions !== undefined && state.PreExistingConditions)) || (yesOrNo === 'No' && (state.PreExistingConditions !== undefined && !state.PreExistingConditions)) ? 'mb-3 ' + css.container50UnSelected : css.container50Selected + ' mb-3'} key={yesOrNo} onClick={() => SetUsePreExisting(yesOrNo)}>
                                {yesOrNo}
                            </div>
                        )
                    }
                </div>) :
                  <Loading />
            }
        </>
    )
}

interface healthPreExistingProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}