import MainLogo from '../assets/main-logo.png';
import css from './home.module.css';
import Next from '../utils/Next';
import { useEffect } from 'react';

export default function HomeInitial(props: homeInitialProps) {

  useEffect(() => {

    props.changeHeaderValue(true, 0, '');

  }, []);

  return (
    <div style={css}>
      <div className={css.divCenter}>
        <img src={MainLogo} alt="main" className='mt-2' style={{ width: "100%" }} />
        <div className='mt-3 mb-1'>
          <div className={css.HeaderOne}>
            YOUR INSURANCE SERVICE STARTS HERE
          </div>
        </div>
        <div className='mb-4'>
          <div className={css.HeaderTwo}>
            Let's find the insurance quote that's right for you.
          </div>
        </div>
        <div className='mb-2'>
          <div className={css.HeaderThree}>
            Your insurance agent will work with you until the job is complete
            and you are 100% satisfied - continuously updating you on
            every step.
          </div>
        </div>
        <div className={css.divNext}>
          <Next To='signup' />
        </div>
      </div>
    </div>
  )
}

interface homeInitialProps {
  changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}