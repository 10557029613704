import { ErrorMessage, Field, useFormikContext } from "formik";
import "./EmailField.css"

export default function EmailField(props: emailFieldProps) {

    const { errors, touched } = useFormikContext<any>();

    return (<div className='mb-3'>
        <Field type="email" name={props.name} id={props.name} placeholder={props.placeholder} className="email-field" />
        {errors[props.name] && touched[props.name] ? (
            <div className="email-field-div"><ErrorMessage name={props.name} /></div>
        ) : null}
    </div>)
}

interface emailFieldProps {
    name: string;
    placeholder: string;
}