import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { FaAngleLeft, FaArrowRight } from "react-icons/fa";
import { fetchLanguages } from "../services/LinqrsApi";
import { LanguageDTO } from "../services/services.model";
import Button from "../utils/Button";
import { LANGUAGES } from "../utils/GlobalConstants";
import Loading from "../utils/Loading";
import css from './auto.module.css';
import { AutoContext } from "./autoContext";

export default function AutoLanguages(props: autoLanguagesProps) {
    const { state, dispatch } = useContext(AutoContext);
    const [languages, setLanguages] = useState<LanguageDTO[]>([]);

    useEffect(() => {

        const fetchLanguagesDataAsync = async () => {
            var languagesSessionData = sessionStorage.getItem(LANGUAGES);

            if (languagesSessionData === null) {
                const response: AxiosResponse<LanguageDTO[]> = await fetchLanguages();
                var data = response.data;
                sessionStorage.setItem(LANGUAGES, JSON.stringify(data));
                setLanguages(data);
            }
            else {
                setLanguages(JSON.parse(languagesSessionData));
            }
        }

        fetchLanguagesDataAsync();

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetLanguages(language: LanguageDTO) {

        if (state.Languages.findIndex(v => v.languageId === language.languageId) > -1) {

            const currentIndex = state.Languages.findIndex(v => v.languageId === language.languageId);
            state.Languages[currentIndex].isSelected = !state.Languages[currentIndex].isSelected;

            dispatch({
                type: "AUTO_LANGUAGES",
                payload: [...state.Languages]
            })
        }
        else {

            language.isSelected = !language.isSelected;

            dispatch({
                type: "AUTO_LANGUAGES",
                payload: [...state.Languages, language]
            })
        }
    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>What languages do you prefer?</div>
            </div>
            {
                languages && languages.length > 0 ? (<div className={css.container}>
                    {
                        languages.map((language) =>
                            <div className={state.Languages && state.Languages.findIndex(v => v.languageId == language.languageId && v.isSelected) > -1 ? 'mb-3 ' + css.containerUnSelected : css.containerSelected + ' mb-3'} key={language.languageId} onClick={() => SetLanguages(language)}>
                                {language.description}
                            </div>
                        )
                    }
                </div>) :
                  <Loading />

            }
            <div className={css.divNext}>
                <Button type="button" className='btn btn-primary' onclick={props.onClick}>
                    <div className="clearfix">
                        <div style={{ float: "left" }}>Next</div>
                        <div style={{ float: "right" }}><FaArrowRight /></div>
                    </div>
                </Button>
            </div>
        </>
    )
}

interface autoLanguagesProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}