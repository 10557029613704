import { homeownerQuotoDto } from "./homeowners.model";



export const homeownerReducer = (state: homeownerQuotoDto, action: any) => {
    switch (action.type) {

        case "QUOTE_TYPE":
            return {
                ...state,
                QuoteType: action.payload
            };

        case "HOMEOWNER_NAME":
            return {
                ...state,
                FirstName: action.payload.FirstName,
                LastName: action.payload.LastName,
            };

        case "HOMEOWNER_DATE_OF_BIRTH":
            return {
                ...state,
                BirthDate: action.payload
            };

        case "HOMEOWNER_CLAIMS":
            return {
                ...state,
                DwellingClaims: action.payload
            };

        case "HOMEOWNER_EMAIL_ADDRESS":
            return {
                ...state,
                EmailAddress: action.payload
            };
        case "HOMEOWNER_ADDRESS":
            return {
                ...state,
                PlaceId: action.payload.placeId,
                FullAddress: action.payload.fullAddress
            };

        case "HOMEOWNER_PHONE_NUMBER":
            return {
                ...state,
                PhoneNumber: action.payload
            };

        case "HOMEOWNER_LANGUAGES":
            return {
                ...state,
                Languages: action.payload
            };
        case "HOMEOWNER_BUNDLES":
            return {
                ...state,
                Bundles: action.payload
            };
        default:
            return state;
    }
}