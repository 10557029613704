import AutoInitial from "./auto/AutoInitial";
import BusinessInitial from "./business/businessInitial";
import HealthInitial from "./health/HealthInitial";
import HomeInitial from "./home/HomeInitial";
import HomeownerInitial from "./homeowners/HomeownersInitial";
import InsuranceInitial from "./insurance/InsuranceInitial";
import InsuranceInitialV2 from "./insurance/InsuranceInitialV2";
import LifeInitial from "./life/LifeInitial";
import RentersInitial from "./renters/RentersInitial";
import TermInitial from "./term/TermInitial";
import RedirectToLandingPage from "./utils/RedirectToLandingPage";


const routes = [
    {path: '/signup', component:InsuranceInitial},
    {path: '/signup/v2', component:InsuranceInitialV2},
    {path: '/signup/compare-auto-insurance-quotes', component:AutoInitial},
    {path: '/signup/compare-homeowners-insurance-quotes', component:HomeownerInitial},
    {path: '/signup/compare-renters-insurance-quotes', component:RentersInitial},
    {path: '/signup/compare-condo-insurance-quotes', component:RentersInitial},
    {path: '/signup/compare-term-insurance-quotes', component:TermInitial},
    {path: '/signup/compare-life-insurance-quotes', component:LifeInitial},
    {path: '/signup/compare-universal-insurance-quotes', component:TermInitial},
    {path: '/signup/compare-health-insurance-quotes', component:HealthInitial},
    {path: '/signup/compare-medicare-insurance-quotes', component:HealthInitial},
    {path: '/signup/compare-business-insurance-quotes', component:BusinessInitial},

    {path: '/', component:HomeInitial},
    {path: '*', component:RedirectToLandingPage}
];

export default routes;