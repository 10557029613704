import { Link, Navigate } from "react-router-dom";
import css from './auto.module.css';
import { FaAngleLeft, FaArrowRight, FaTrashAlt } from "react-icons/fa";
import { useContext, useEffect, useRef, useState } from "react";
import { AutoContext } from "./autoContext";
import { NavLink } from "react-router-dom";
import Button from '../utils/Button';
import { AutomobileDTO, AutomobileModelDTO, SelectOption, VehicleDTO } from "../services/services.model";
import Select from 'react-select';
import { GetYears } from "../utils/global.functions";
import { fetchAutomobileModels, fetchAutomobiles } from "../services/LinqrsApi";
import { NUMBER_OF_AUTOMOBILES } from "../utils/GlobalConstants";
import { AxiosResponse } from "axios";
import Loading from "../utils/Loading";


export default function AutoCars(props: propsAutoCars) {

    const { state, dispatch } = useContext(AutoContext);
    const [automobiles, setAutomobiles] = useState<AutomobileDTO[]>([]);
    const [automobileOptions, setAutomobileOptions] = useState<SelectOption[]>([]);
    const [automobileMakeOptions, setAutomobileMakeOptions] = useState<SelectOption[]>([]);
    const [automobileYearOptions, setAutomobileYearOptions] = useState<SelectOption[]>([]);

    const [vehicleMake, setVehicleMake] = useState<string | undefined>(undefined);
    const [vehicleModel, setVehicleModel] = useState<string | undefined>(undefined);
    const [vehicleYear, setVehicleYear] = useState<string | undefined>(undefined);

    const [isVehicleMakeLoading, setVehicleMakeLoading] = useState<boolean>(true);
    const [isVehicleModelLoading, setVehicleModelLoading] = useState<boolean>(false);

    const [isVehicleModelDisabled, setVehicleModelDisabled] = useState<boolean>(true);
    const [isVehicleYearDisabled, setVehicleYearDisabled] = useState<boolean>(true);

    const [isAddVehicleDisabled, setAddVehicleDisabled,] = useState<boolean>(true);

    const [hiddenOther, setHiddenOther] = useState<boolean>(true);

    let selectInputYearRef: any = null;
    let selectInputModelRef: any = null;
    let selectInputMakeRef: any = null;

    const years = GetYears().map(v => {
        return { value: v, label: v };
    });

    useEffect(() => {

        const fetchNumberOfCarsDataAsync = async () => {

            sleep(25000);
            var numberOfAutomobileSessionData = sessionStorage.getItem(NUMBER_OF_AUTOMOBILES);

            let options: AutomobileDTO[] = [];

            if (numberOfAutomobileSessionData === null) {

                const response: AxiosResponse<AutomobileDTO[]> = await fetchAutomobiles();
                options = response.data;
                sessionStorage.setItem(NUMBER_OF_AUTOMOBILES, JSON.stringify(options));
            }
            else {
                options = JSON.parse(numberOfAutomobileSessionData);
            }

            setAutomobiles(options);
        }

        fetchNumberOfCarsDataAsync();

        setAutomobileYearOptions(years);
        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    const sleep = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {

        if (automobiles.length === 0)
            return;

        setAutomobileOptions(automobiles.sort((a, b) => a.automobileName.localeCompare(b.automobileName)).map(a => {
            return { value: a.automobileId.toString(), label: a.automobileName };
        }));

        setVehicleMakeLoading(false);

    }, [automobiles]);

    useEffect(() => {

        isValid();

    }, [vehicleMake, vehicleYear, vehicleModel]);

    const fetchAutomobileModelDataAsync = async (id: number) => {

        setVehicleModelLoading(true);

        let options: AutomobileModelDTO[] = [];
        const response: AxiosResponse<AutomobileModelDTO[]> = await fetchAutomobileModels(id);
        options = response.data;

        options.push({
            automobileModelId: -1,
            automobileId: id,
            modelName: 'Other'
        })

        setAutomobileMakeOptions(options.sort((a, b) => a.modelName.localeCompare(b.modelName)).map(a => {
            return { value: a.modelName, label: a.modelName };
        }));

        setVehicleModelLoading(false);
        setVehicleModelDisabled(false);
    }


    const deleteVehicle = (vehicle: VehicleDTO): void => {

        dispatch({
            type: "AUTO_DELETE_CARS",
            payload: vehicle
        });
    }

    const isValid = () => {
        if (vehicleMake !== null && vehicleMake !== undefined && vehicleModel !== null && vehicleModel !== undefined && vehicleYear !== null && vehicleYear !== undefined) {
            setAddVehicleDisabled(false);
        }
        else {
            setAddVehicleDisabled(true);
        }
    }

    const addVehicle = () => {

        dispatch({
            type: "AUTO_ADD_CARS",
            payload: {
                make: vehicleMake,
                model: vehicleModel,
                year: vehicleYear,
                created: Date.now()
            }
        });

        selectInputMakeRef.clearValue();
        resetSelect();
    }

    const resetSelect = () => {
        setVehicleMake(undefined);
        setVehicleModel(undefined);
        setVehicleYear(undefined);
        selectInputYearRef.clearValue();
        selectInputModelRef.clearValue();
        setVehicleModelDisabled(true);
        setVehicleYearDisabled(true);
        setAddVehicleDisabled(true);
    }

    const customStyles = {
        control: (base: any) => ({
            ...base,
            height: "3.125rem",
            minHeight: "3.125rem"
        })
    };

    return (
        <>
            <div><NavLink to={`/signup`}><FaAngleLeft className={css.backArrow} /></NavLink> </div>
            <div className="mb-3">
                <div className={css.divCenterText}>Tell us about your Vehicle(s)</div>
            </div>
            <div className="mb-3">
                <div className={css.divCenterTextSmall}>
                    You can save up to 20% when you bundle your car with home or renters insurance!
                </div>
            </div>
            <div>
                <div className='mb-3'>
                    <Select
                        ref={ref => {
                            selectInputMakeRef = ref;
                        }}
                        placeholder="Select Vehicle Make ...."
                        isDisabled={false}
                        isLoading={isVehicleMakeLoading}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        options={automobileOptions}
                        styles={customStyles}
                        components={{ LoadingIndicator: Loading }}
                        onChange={(e) => {

                            if (e === undefined || e === null) {
                                resetSelect();
                                return;
                            }

                            setVehicleMake(e.label);
                            fetchAutomobileModelDataAsync(parseInt(e.value));
                        }}
                    />
                </div>

                <div className='mb-3'>
                    <Select
                        ref={ref => {
                            selectInputModelRef = ref;
                        }}
                        placeholder="Select Vehicle Model (pick 'Other' if N/A) ...."
                        isDisabled={isVehicleModelDisabled}
                        isLoading={isVehicleModelLoading}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        options={automobileMakeOptions}
                        styles={customStyles}
                        components={{ LoadingIndicator: Loading }}
                        onChange={(e) => {
                            if (e === undefined || e === null) {
                                setVehicleModel(undefined);
                                setVehicleYearDisabled(true);
                                setHiddenOther(true);
                                selectInputYearRef.clearValue();
                                return;
                            }

                            if (e.value === 'Other') {
                                setHiddenOther(false);
                            }
                            else {
                                setHiddenOther(true);
                                setVehicleModel(e.value);
                            }
                            setVehicleYearDisabled(false);

                        }}
                    />
                    <div className='mt-2' hidden={hiddenOther} >
                        <input type="text" placeholder='Type vehicle model here ...' style={{ height: "3.125rem", width: "100%" }}
                            onChange={(e) => {

                                if (e.target.value === '' || e.target.value === null || e.target.value === undefined) {
                                    setVehicleModel(undefined)
                                    return;
                                }
                                setVehicleModel(e.target.value);
                            }

                            } />
                    </div>
                </div>

                <div className='mb-3'>
                    <Select
                        ref={ref => {
                            selectInputYearRef = ref;
                        }}
                        placeholder="Select Vehicle Year ...."
                        isDisabled={isVehicleYearDisabled}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        options={automobileYearOptions}
                        styles={customStyles}
                        onChange={(e) => {
                            if (e === undefined || e === null) {
                                setVehicleYear(undefined);
                                return;
                            }

                            setVehicleYear(e.value);
                        }}
                    />
                </div>

                <div className={css.divFlex}>
                    <button style={{ width: "12.5rem" }} disabled={isAddVehicleDisabled} className={`${isAddVehicleDisabled ? 'btn btn-light' : 'btn btn-secondary'}`} onClick={addVehicle}>Add Vehicle</button>
                    {
                        state.VehiclesWeb && state.VehiclesWeb.length > 0 ? (<Button className='btn btn-primary' onclick={props.onClick} >
                            <div className="clearfix">
                                <div style={{ float: "left" }}>Next</div>
                                <div style={{ float: "right" }}><FaArrowRight /></div>
                            </div>
                        </Button>) :
                            <></>

                    }

                </div>
                {
                    state.VehiclesWeb && state.VehiclesWeb.length > 0 ? (<div className={css.divFlexCol}>
                        {
                            state.VehiclesWeb.map((vehicle) =>
                                <div key={vehicle.created}>
                                    <FaTrashAlt color='red' cursor='pointer' onClick={() => deleteVehicle(vehicle)} /> {vehicle.make} {vehicle.model} {vehicle.year}
                                </div>
                            )
                        }
                    </div>) :
                        <></>

                }
            </div>
        </>
    )
}

interface propsAutoCars {
    onClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}