import React, { useEffect, useReducer, useRef, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from './routes-config';
import Header from './Header';
import Footer from './Footer';
import { InsuranceContext } from './insurance/insuranceContext';
import { insuranceReducer } from './insurance/insuranceReducer';
import configureValidations from './Validation';
import { headerValue } from './global';
import TagManager from 'react-gtm-module';

configureValidations();

function App() {

  const [state, dispatch] = useReducer(insuranceReducer, { QuoteType: { description: '', descriptionAbbr: '', isWebEnabled: false, wizardEnabled: false }, Bundles: [], V2: false });

  const [isSticky, setSticky] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

  useEffect(() => {

    TagManager.initialize({ gtmId: 'G-T8QXN8L' });
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const [header, setHeader] = useState<headerValue>({
    hidden: true,
    now: 0,
    percentage: ''
  })

  const changeState = (hidden: boolean, now: number, percentage: string) => {
    setHeader({
      hidden: hidden,
      now: now,
      percentage: percentage
    });
  };

  return (
    <BrowserRouter>
      <div className='Wrap'>
        <div className={`sticky-wrapper${isSticky ? ' sticky' : ''}`} ref={ref}>
          <Header {...header} />
        </div>
        <div className='Middle'>
          {
            <InsuranceContext.Provider value={{ state, dispatch }}>
              <Routes>
                {
                  routes.map((route) => (
                    <Route key={route.path} path={route.path} element={<route.component changeHeaderValue={changeState} />} />
                  ))
                }
              </Routes>
            </InsuranceContext.Provider>
          }
        </div>
        <div className='Footer'>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
