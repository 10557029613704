import css from './health.module.css';
import { TextField } from "@mui/material";
import Button from '../utils/Button';
import { FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import { useContext, useEffect, useRef, useState } from 'react';
import AddressCompleteField from '../utils/AddressCompleteField';
import { HealthContext } from './healthContext';

export default function HealthAddress(props: healthAddressProps) {
    const { state, dispatch } = useContext(HealthContext);
    const [placeId, setPlaceId] = useState(state.PlaceId);
    const [fullAddress, setFullAddress] = useState(state.FullAddress);
    const [country, setCountry] = useState("us");
    const [errors, setErrors] = useState('');

    useEffect(() => {

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetAddress() {

        if (placeId === null || placeId == undefined || placeId.trim() === "") {
            setErrors('Address is required');
            return;
        }

        dispatch({
            type: "HEALTH_ADDRESS",
            payload: {
                placeId: placeId,
                fullAddress: fullAddress
            }
        })

        props.onClick();
    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>What is your address?</div>
            </div>
            <div className='mb-3'>
                <AddressCompleteField fullAddress={fullAddress} placeholder='Enter your address'
                    onPlaceSelected={(place_id, formatted_address) => {
                        {
                            setFullAddress(formatted_address);
                            setPlaceId(place_id);
                        }
                    }} onChange={() => {
                        setErrors('');
                        setPlaceId('');
                        setFullAddress('');
                    }} />
                {errors ? (
                    <div style={{ color: "red", fontSize: "0.875rem" }}>{errors}</div>
                ) : null}
            </div>
            <div className={css.divNext}>
                <Button type="button" className='btn btn-primary' onclick={SetAddress}>
                    <div className="clearfix">
                        <div style={{ float: "left" }}>Next</div>
                        <div style={{ float: "right" }}><FaArrowRight /></div>
                    </div>
                </Button>
            </div>
        </>
    )
}

interface healthAddressProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}