import { ThreeDots } from 'react-loader-spinner'

export default function Loading() {
    return (
        <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', width: '100%'}}>
            <ThreeDots
                height="100"
                width="100"
                radius="9"
                color="#0dafff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                visible={true}
            />
        </div>
    )
}