import { useContext, useEffect, useReducer, useState } from "react";
import InsuranceBundle from "../insurance/InsuranceBundle";
import { InsuranceContext } from "../insurance/insuranceContext";
import { InitialDateOfBirth } from "../utils/global.functions";
import RedirectToLandingPage from "../utils/RedirectToLandingPage";
import { autoQuotoDto } from "./auto.model";
import AutoAddress from "./AutoAddress";
import AutoBirth from "./AutoBirth";
import AutoCarrier from "./AutoCarrier";
import AutoCars from "./AutoCars";
import { AutoContext } from "./autoContext";
import AutoDriver from "./AutoDriver";
import AutoDrivers from "./AutoDrivers";
import AutoEmail from "./AutoEmail";
import AutoGender from "./AutoGenders";
import AutoLanguage from "./AutoLanguages";
import { autoReducer } from "./autoReducer";
import AutoViolations from "./AutoViolations";
import css from './auto.module.css';
import PhoneForm from "../utils/PhoneForm";
import SubmitForm from "../utils/SubmitForm";
import AutoSR from "./AutoSR";
import AutoMarital from "./AutoMarital";
import { AUTO_QUOTE_TYPE } from "../utils/GlobalConstants";

export default function AutoInitial(props: autoInitialProps) {

    const totalIndex = 15;
    const context = useContext(InsuranceContext);
    let footerDescr = '';

    const auto: autoQuotoDto = {
        FirstName: '',
        LastName: '',
        Violations: [],
        EmailAddress: '',
        PlaceId: '',
        FullAddress: '',
        Languages: [],
        Bundles: [],
        VehiclesWeb:[],
        BirthDate: InitialDateOfBirth()
    }

    const [state, dispatch] = useReducer(autoReducer, auto);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {

        dispatch({
            type: "QUOTE_TYPE",
            payload: AUTO_QUOTE_TYPE
        })

    }, []);

    function NextClick() {

        setCurrentIndex(i => i + 1);
    }

    function BackClick() {
        setCurrentIndex(i => i - 1);
    }

    function displayWizard() {
        if (currentIndex === 0) {
            footerDescr = ''
            return (<>
                <AutoCars onClick={NextClick} changeHeaderValue={props.changeHeaderValue} currentIndex={1} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 1) {
            footerDescr = '';
            return (<>
                <AutoDriver onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={2} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 2) {
            footerDescr = ''
            return (<>
                <AutoBirth onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={3} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 3) {
            footerDescr = 'Save an extra 12% by bundling';
            return (<>
                <AutoGender onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={4} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 4) {
            return (<>
                <AutoMarital onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={5} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 5) {
            return (<>
                <AutoCarrier onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={6} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 6) {
            footerDescr = 'Linqrs will never sell your personal information'
            return (<>
                <AutoDrivers onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={7} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 7) {
            return (<>
                <AutoViolations onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={8} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 8) {
            return (<>
                <AutoSR onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={9} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 9) {
            return (<>
                <AutoLanguage onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={10} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 10) {
            return (<>
                <AutoEmail onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={11} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 11) {
            return (<>
                <AutoAddress onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={12} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 12) {
            return (<>
                <PhoneForm onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} phoneNumber={state.PhoneNumber} v2={context.state.V2} updatePhoneNumber={(phoneNumber) => {
                     dispatch({
                        type: "AUTO_PHONE_NUMBER",
                        payload: phoneNumber
                    })
                }} currentIndex={13} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 13) {
            return (<>
                <InsuranceBundle onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} updateBundle={() => {
                    state.Bundles = context.state.Bundles.map(({ quoteMenuTypeKey }) => quoteMenuTypeKey);
                }} currentIndex={14} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 14) {
            return (<>
                <SubmitForm onClick={NextClick}
                    changeHeaderValue={props.changeHeaderValue}
                    title="You're seconds away from getting quick, cheap auto insurance quotes!"
                    firstName={state.FirstName}
                    quoteType={state.QuoteType}
                    quoteData={JSON.stringify(state)} />
            </>)
        }
        else {
            return (
                <RedirectToLandingPage />
            )
        }
    }

    return (
        <div style={css}>
            <div className={css.divCenter}>
                <AutoContext.Provider value={{ state, dispatch }}>
                    {

                        displayWizard()
                    }
                </AutoContext.Provider>
                <div className="mb-3">
                    <div className={css.divCenterTextSmall}>
                        {footerDescr}
                    </div>
                </div>
            </div>
        </div>
    )
}

interface autoInitialProps {
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}