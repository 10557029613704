import { termQuotoDto } from "./term.model";



export const termReducer = (state: termQuotoDto, action: any) => {
    switch (action.type) {

        case "QUOTE_TYPE":
            return {
                ...state,
                QuoteType: action.payload
            };

        case "TERM_NAME":
            return {
                ...state,
                FirstName: action.payload.FirstName,
                LastName: action.payload.LastName,
            };

        case "TERM_DATE_OF_BIRTH":
            return {
                ...state,
                BirthDate: action.payload
            };

        case "TERM_AMOUNT":
            return {
                ...state,
                InsuranceAmount: action.payload
            };

        case "TERM_PEOPLE":
            return {
                ...state,
                NumberOfPeople: action.payload
            };

        case "TERM_NICOTINE":
            return {
                ...state,
                UseNicotine: action.payload
            };

            case "TERM_GENDER":
                return {
                  ...state,
                  Gender: action.payload
                };

        case "TERM_EMAIL_ADDRESS":
            return {
                ...state,
                EmailAddress: action.payload
            };
        case "TERM_ADDRESS":
            return {
                ...state,
                PlaceId: action.payload.placeId,
                FullAddress: action.payload.fullAddress
            };

        case "TERM_PHONE_NUMBER":
            return {
                ...state,
                PhoneNumber: action.payload
            };

        case "TERM_LANGUAGES":
            return {
                ...state,
                Languages: action.payload
            };
        case "TERM_HEIGHT":
            return {
                ...state,
                Height: action.payload
            };
        case "TERM_WEIGHT":
            return {
                ...state,
                Weight: action.payload
            };
        case "TERM_BUNDLES":
            return {
                ...state,
                Bundles: action.payload
            };
        default:
            return state;
    }
}