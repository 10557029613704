import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Next(props: nextProps) {
    return (
        <>
            <Link className='btn btn-primary' to={`/${props.To}`} style={{
                width: "7.5rem"
            }}><div className="clearfix">
                    <div style={{ float: "left" }}>Next</div>
                    <div style={{ float: "right" }}><FaArrowRight /></div>
                </div></Link>
        </>
    )
}

interface nextProps {
    To: string;
}