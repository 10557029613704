import css from './auto.module.css';
import { TextField } from "@mui/material";
import Button from '../utils/Button';
import { FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import { AutoContext } from './autoContext';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

export default function AutoEmail(props: autoEmailProps) {
    const { state, dispatch } = useContext(AutoContext);
    const [emailAddress, setEmailAddress] = useState(state.EmailAddress);

    useEffect(() => {

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    const emailSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email address required')
    });

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>What is your email?</div>
            </div>
            <Formik
                initialValues={{
                    email: emailAddress
                }}
                validationSchema={emailSchema}
                onSubmit={values => {

                    dispatch({
                        type: "AUTO_EMAIL_ADDRESS",
                        payload: values.email
                    })

                    props.onClick();
                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className='mb-3'>
                            <Field name="email" type="email" placeholder="Enter your email address" style={{ width: "100%", height: "3.125rem" }} />
                            {errors.email && touched.email ? (
                                <div style={{ color: "red", fontSize: "0.875rem" }}>{errors.email}</div>
                            ) : null}
                        </div>
                        <div className={css.divNext}>
                            <Button type="submit" className='btn btn-primary'>
                                <div className="clearfix">
                                    <div style={{ float: "left" }}>Next</div>
                                    <div style={{ float: "right" }}><FaArrowRight /></div>
                                </div>
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

interface autoEmailProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}