export function InitialDateOfBirth(): Date {
    const date = new Date();
    date.setFullYear(new Date().getFullYear() - 16);
    return date;
};

export function ValidatePhoneForE164(phoneNumber: string) {
    const regEx = /^\+[1-9]\d{10,14}$/;

    return regEx.test(phoneNumber);
};

export function GetYears(): string[] {
    var years: string[] = [];
    var maxYear = new Date().getFullYear() + 1;

    for (let i = maxYear; i >= 1900; i--) {
        years.push(i.toString());
    }

    return years;
};