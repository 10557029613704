import css from './auto.module.css';
import { FaAngleLeft, FaArrowRight } from "react-icons/fa";
import Button from "../utils/Button";
import { useContext, useEffect, useState } from 'react';
import { AutoContext } from './autoContext';
import moment from 'moment';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

export default function AutoBirth(props: propsAutoBirth) {
    const { state, dispatch } = useContext(AutoContext);
    const [birthDate, setBirthDate] = useState(moment(state.BirthDate).format("YYYY-MM-DD"));

    const dateOfBirthSchema = Yup.object().shape({
        birthDate: Yup.date().required('Date of Birth Required').MininumAge(16),
    });

    useEffect(() => {

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>What's your date of birth?</div>
            </div>
            <Formik
                initialValues={{
                    birthDate: birthDate
                }}
                validationSchema={dateOfBirthSchema}
                onSubmit={values => {

                    dispatch({
                        type: "AUTO_DATE_OF_BIRTH",
                        payload: values.birthDate
                    })

                    props.onClick();
                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className='mb-3'>
                            <Field type="date" name="birthDate" />
                            {errors.birthDate && touched.birthDate ? (
                                <div style={{ color: "red", fontSize: "0.875rem" }}>{errors.birthDate}</div>
                            ) : null}
                        </div>
                        <div className={css.divNext}>
                            <Button type="submit" className='btn btn-primary'>
                                <div className="clearfix">
                                    <div style={{ float: "left" }}>Next</div>
                                    <div style={{ float: "right" }}><FaArrowRight /></div>
                                </div>
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

interface propsAutoBirth {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}