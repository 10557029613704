import { createContext } from "react";
import { InsuranceQuoteDTO } from "./insurance.model";


export const InsuranceContext = createContext<{
    state: InsuranceQuoteDTO;
    dispatch: React.Dispatch<any>;
  }>({
    state: {QuoteType:{description: '', descriptionAbbr: '', isWebEnabled: false, wizardEnabled: false}, Bundles:[], V2: false},
    dispatch: () => null
  });