import { businessQuotoDto } from "./business.model";



export const businessReducer = (state: businessQuotoDto, action: any) => {
    switch (action.type) {

        case "QUOTE_TYPE":
            return {
                ...state,
                QuoteType: action.payload
            };

        case "BUSINESS_NAME":
            return {
                ...state,
                BusinessName: action.payload.BusinessName,
            };

        case "BUSINESS_FULL_NAME":
            return {
                ...state,
                FirstName: action.payload.FirstName,
                LastName: action.payload.LastName,
            };

        case "BUSINESS_POLICY":
            return {
                ...state,
                TypeOfPolicy: action.payload
            };

        case "BUSINESS_RISK":
            return {
                ...state,
                BusinessRisk: action.payload
            };

        case "BUSINESS_PEOPLE":
            return {
                ...state,
                AmountOfEmployees: action.payload
            };

        case "BUSINESS_COMMERCIAL_AUTO":
            return {
                ...state,
                CommercialAutoCoverage: action.payload
            };

            case "BUSINESS_INSURED":
            return {
                ...state,
                BusinessInsuredCurrently: action.payload
            };


        case "BUSINESS_FUNCTION":
            return {
                ...state,
                BusinessFunction: action.payload.BusinessFunction
            };

        case "BUSINESS_EMAIL_ADDRESS":
            return {
                ...state,
                EmailAddress: action.payload
            };
        case "BUSINESS_ADDRESS":
            return {
                ...state,
                PlaceId: action.payload.placeId,
                FullAddress: action.payload.fullAddress
            };

        case "BUSINESS_CLAIMS_LAST_3_YEARS":
            return {
                ...state,
                ClaimsLast3Years: action.payload
            };

        case "BUSINESS_PHONE_NUMBER":
            return {
                ...state,
                PhoneNumber: action.payload
            };
        default:
            return state;
    }
}