import css from './business.module.css';
import Button from '../utils/Button';
import { FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import EmailField from '../utils/EmailField';
import { BusinessContext } from './businessContext';

export default function BusinessEmail(props: businessEmailProps) {
    const { state, dispatch } = useContext(BusinessContext);
    const [emailAddress, setEmailAddress] = useState(state.EmailAddress);

    useEffect(() => {

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    const emailSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email address required')
    });

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>What is your email?</div>
            </div>
            <Formik
                initialValues={{
                    email: emailAddress
                }}
                validationSchema={emailSchema}
                onSubmit={values => {

                    dispatch({
                        type: "BUSINESS_EMAIL_ADDRESS",
                        payload: values.email
                    })

                    props.onClick();
                }}
            >
                <Form>
                    <EmailField name='email' placeholder='Enter your email address' />
                    <div className={css.divNext}>
                        <Button type="submit" className='btn btn-primary'>
                            <div className="clearfix">
                                <div style={{ float: "left" }}>Next</div>
                                <div style={{ float: "right" }}><FaArrowRight /></div>
                            </div>
                        </Button>
                    </div>
                </Form>
            </Formik>
        </>
    )
}

interface businessEmailProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}