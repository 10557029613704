import Modal from 'react-bootstrap/Modal';

export default function Help(props: helpProps) {

    const spanStyle = {
        fontSize: '1.375em',
        fontWeight: "bold",
     }

    return (<>
        <Modal show={props.show} onHide={props.hide} style={{ height: "100%" }}>
            <Modal.Header closeButton>
                <Modal.Title>FAQ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span style={spanStyle}>How much does Linqrs charge for this service?</span>
                <div className='mb-2'>
                    This service is free of charge. Get all the free quotes that you need.
                </div>
                <span style={spanStyle}>How do you help me find insurance?</span>
                <div className='mb-2'>
                    After going through our wizard you will receive quotes from local agents in your area 
                    who represent the best insurance carriers nationwide. The agent will answer any questions
                    you may have about coverage and assist you in finding an insurance policy quickly while meeting
                    your needs economically.
                </div>
                <span style={spanStyle}>How many insurance providers do you work with?</span>
                <div className='mb-2'>
                   We work directly with a numerous amount of regional agencies and broker networks to provide a wide array of
                   opportunities to match you with money saving opportunities.
                </div>
                <span style={spanStyle}>When will I be contacted with insurance quotes?</span>
                <div className='mb-2'>
                   Insurance providers and local agents typically begin contacting you within seconds of your submission. Everything happens in real time. Once you submit
                   your request for an insurance quote at most three agents will be in contact you with a competitive quote.
                </div>
                <span style={spanStyle}>How will my information be used?</span>
                <div className='mb-2'>
                  The information you provide Linqrs will be shared with insurance agents, brokers and providers that intend to assist you with insurance
                  quotes and policy information. We will not sell or redistribute your information to any other third party other than in compliance with
                  our privacy policy.
                </div>
            </Modal.Body>
        </Modal>
    </>)
}

interface helpProps {
    show: boolean;
    hide(): void;
}