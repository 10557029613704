import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { FaAngleLeft, FaArrowRight } from "react-icons/fa";
import { fetchInsuranceAmounts } from "../services/LinqrsApi";
import { InsuranceAmountDTO } from "../services/services.model";
import { INSURANCE_AMOUNTS } from "../utils/GlobalConstants";
import { TermContext } from "./termContext";
import css from './term.module.css';
import { NavLink } from "react-router-dom";
import Loading from "../utils/Loading";
import { InsuranceContext } from "../insurance/insuranceContext";

export default function TermAmount(props: termAmountProps) {

    const { state, dispatch } = useContext(TermContext);
    const context = useContext(InsuranceContext);
    const [insuranceAmounts, setInsuranceAmounts] = useState<InsuranceAmountDTO[]>([]);

    let titleDescr = `How much ${context.state.QuoteType.description.toLowerCase()} insurance do you need?`;


    useEffect(() => {

        const fetchInsuranceAmountsDataAsync = async () => {
            var insuranceAmountsSessionData = sessionStorage.getItem(INSURANCE_AMOUNTS);

            if (insuranceAmountsSessionData === null) {
                const response: AxiosResponse<InsuranceAmountDTO[]> = await fetchInsuranceAmounts();
                var data = response.data;
                sessionStorage.setItem(INSURANCE_AMOUNTS, JSON.stringify(data));
                setInsuranceAmounts(data);
            }
            else {
                setInsuranceAmounts(JSON.parse(insuranceAmountsSessionData));
            }
        }

        fetchInsuranceAmountsDataAsync();
        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetInsuranceAmounts(insuranceAmount: InsuranceAmountDTO) {

        insuranceAmount.isSelected = !insuranceAmount.isSelected;

        dispatch({
            type: "TERM_AMOUNT",
            payload: insuranceAmount
        })

        props.onClick();
    }

    return <>
    <div><NavLink to={`/signup`}><FaAngleLeft className={css.backArrow} /></NavLink> </div>
            <div className='mb-3'>
                <div className={css.divCenterText}>{titleDescr}</div>
            </div>
            <div className="mb-3">
                <div className={css.divCenterTextSmall}>
                    You can save up to 20% when you bundle your policy with your car insurance!
                </div>
            </div>
            {
                insuranceAmounts && insuranceAmounts.length > 0 ? (
                    <div className={css.container}>
                        {
                           insuranceAmounts.map((insuranceAmount) =>
                           <div className={insuranceAmount.insuranceAmountId === state.InsuranceAmount?.insuranceAmountId ? 'mb-3 ' + css.containerUnSelected : css.containerSelected + ' mb-3'} key={insuranceAmount.insuranceAmountId} onClick={() => SetInsuranceAmounts(insuranceAmount)}>
                               {insuranceAmount.description}
                           </div>
                            )
                        }
                    </div>
                ) : <Loading />
            }
    </>
}

interface termAmountProps {
    onClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}