
import React, { createContext } from "react";
import { businessQuotoDto } from "./business.model";



export const BusinessContext = createContext<{
    state: businessQuotoDto;
    dispatch: React.Dispatch<any>;
}>({
    state: {
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        PlaceId: '',
        TypeOfPolicy: '',
        BusinessRisk: '',
        FullAddress: '',
        AmountOfEmployees: '',
        BusinessName: '',
        BusinessFunction: '',
        Bundles:[]
    },
    dispatch: () => null
});