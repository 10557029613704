import moment from 'moment';
import * as Yup from 'yup';

function configureValidations()
{
    Yup.addMethod(Yup.date, 'MininumAge', function(age: number) {
        return this.test('Mininum-Age', `Must be ${age} years or older`, function(value) {
           return moment().diff(value, 'years') >= age;
        })
    })

    Yup.addMethod(Yup.string, 'ValidateCode', function(code: string) {
        return this.test('Validate-Code', `Invalid verification code`, function(value) {
            return value === code;
        })
    })
}

export default configureValidations;