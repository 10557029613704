import { TermContext } from "./termContext";
import css from './term.module.css';
import { InsuranceContext } from "../insurance/insuranceContext";
import { termQuotoDto } from "./term.model";
import { InitialDateOfBirth } from "../utils/global.functions";
import { useContext, useEffect, useReducer, useState } from "react";
import { termReducer } from "./termReducer";
import TermAmount from "./TermAmount";
import RedirectToLandingPage from "../utils/RedirectToLandingPage";
import TermPeople from "./TermPeople";
import TermName from "./TermName";
import TermBirth from "./TermBirth";
import TermGender from "./TermGender";
import TermNicotine from "./TermNicotine";
import TermEmail from "./TermEmail";
import TermAddress from "./TermAddress";
import PhoneForm from "../utils/PhoneForm";
import SubmitForm from "../utils/SubmitForm";
import InsuranceBundle from "../insurance/InsuranceBundle";
import TermLanguages from "./TermLanguages";
import TermHeight from "./TermHeight";
import TermWeight from "./TermWeight";
import {TERM_QUOTE_TYPE } from "../utils/GlobalConstants";

export default function TermInitial(props: termInitialProps) {
    const totalIndex = 14;
    const context = useContext(InsuranceContext);

    const term: termQuotoDto = {
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        PlaceId: '',
        FullAddress: '',
        Languages: [],
        BirthDate: InitialDateOfBirth(),
        Bundles: []
    }

    const [state, dispatch] = useReducer(termReducer, term);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {

        dispatch({
            type: "QUOTE_TYPE",
            payload: TERM_QUOTE_TYPE
        })

    }, []);

    function NextClick() {

        setCurrentIndex(i => i + 1);
    }

    function BackClick() {
        setCurrentIndex(i => i - 1);
    }

    function displayWizard() {
        if (currentIndex === 0) {

            return (<>
                <TermAmount onClick={NextClick} changeHeaderValue={props.changeHeaderValue} currentIndex={1} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 1) {
            return (<>
                <TermPeople onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={2} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 2) {
            return (<>
                <TermName onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={3} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 3) {
            return (<>
                <TermBirth onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={4} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 4) {
            return (<>
                <TermGender onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={5} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 5) {
            return (<>
                <TermHeight onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={6} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 6) {
            return (<>
                <TermWeight onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={7} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 7) {
            return (<>
                <TermNicotine onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={8} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 8) {
            return (<>
                <TermEmail onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={9} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 9) {
            return (<>
                <TermAddress onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={10} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 10) {
            return (<>
                <PhoneForm onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} phoneNumber={state.PhoneNumber} v2={context.state.V2} updatePhoneNumber={(phoneNumber) => {
                    dispatch({
                        type: "TERM_PHONE_NUMBER",
                        payload: phoneNumber
                    })
                }} currentIndex={11} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 11) {
            return (<>
                <TermLanguages onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue} currentIndex={12} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 12) {
            return (<>
                <InsuranceBundle onClick={NextClick} onBackClick={BackClick} changeHeaderValue={props.changeHeaderValue}
                    updateBundle={() => {
                        state.Bundles = context.state.Bundles.map(({ quoteMenuTypeKey }) => quoteMenuTypeKey);
                    }} currentIndex={13} totalIndex={totalIndex} />
            </>)
        }
        else if (currentIndex === 13) {
            return (<>
                <SubmitForm onClick={NextClick}
                    changeHeaderValue={props.changeHeaderValue}
                    title="You're seconds away from your life quotes!"
                    firstName={state.FirstName}
                    quoteType={state.QuoteType}
                    quoteData={JSON.stringify(state)} />
            </>)
        }
        else {
            return (
                <RedirectToLandingPage />
            )
        }
    }

    return (
        <div style={css}>
            <div className={css.divCenter}>
                <TermContext.Provider value={{ state, dispatch }}>
                    {
                        displayWizard()
                    }
                </TermContext.Provider>
            </div>
        </div>
    )
}

interface termInitialProps {
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}