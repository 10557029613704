import { useContext, useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { QuoteMenuTypeKey, saveQuote } from '../services/LinqrsApi';
import Button from '../utils/Button';
import Loading from '../utils/Loading';
import css from './submitForm.module.css';
import { LINQRS_WEBSITE } from './GlobalConstants';

export default function SubmitForm(props: submitFormProps) {

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        props.changeHeaderValue(true, 0, '');

    }, []);

    async function OnSubmit() {

        try {

            setLoading(true);

            await saveQuote({
                quoteType: props.quoteType,
                jsonData: props.quoteData
            });

        } catch (error) {

        }

        setLoading(false);
        window.location.replace(LINQRS_WEBSITE)
    }

    return (
        <>
            <div className='mb-3'>
                <div className={css.HeaderOne}>{props.title}</div>
            </div>
            <div className='mb-3'>
                <div className={css.HeaderTwo}>All done {props.firstName}! Thanks for filling out those questions. Based on your answers, we will match you with local insurance agents in your area to find you the best affordable insurance. Once you submit your request, an array of local agents will be notified and will contact you immediately with a quote.</div>
            </div>
            <div className='mb-3'>
                {
                    !loading ? (
                        <div className={css.divCenterText}>
                            <Button type="button" className='btn btn-success' onclick={OnSubmit}>Submit</Button>
                        </div>
                    ) :
                    <Loading />
                }
            </div>
            <div className='mb-3'>
                <div className={css.HeaderThree}>
                    <span>Linqrs Ltd will never sell your personal information</span>
                </div>
                <div className={css.HeaderThree}>
                    <span><FaLock />&nbsp;128 bit encryption</span>
                </div>
            </div>
        </>
    );
}

interface submitFormProps {
    title: string;
    firstName: string;
    quoteType: QuoteMenuTypeKey;
    quoteData: string;
    onClick(): void;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}