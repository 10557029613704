import css from './business.module.css';
import Button from '../utils/Button';
import { FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import NameField from '../utils/NameField';
import { BusinessContext } from './businessContext';

export default function BusinessFunction(props: businessFunctionProps) {

    const { state, dispatch } = useContext(BusinessContext);
    const [businessFunction, setBusinessFunction] = useState(state.BusinessFunction);

    const businessFunctionSchema = Yup.object().shape({
        businessFunction: Yup.string().trim().min(1, 'Business description too Short!').max(150, 'Business description too Long!').required('Business description Required'),
    });

    useEffect(() => {
        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);
    }, []);

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>In a few words, can you please describe what your business does?</div>
            </div>
            <Formik
                initialValues={{
                    businessFunction: businessFunction,
                }}
                validationSchema={businessFunctionSchema}
                onSubmit={values => {
     
                    dispatch({
                        type: "BUSINESS_FUNCTION",
                        payload: {
                            BusinessFunction: values.businessFunction,
                        }
                    })
                    props.onClick();
                }}
            >
                <Form>
                    <NameField name='businessFunction' placeholder='Type here ...' IsTrim={true} />
                    <div className={css.divNext}>
                        <Button type="submit" className='btn btn-primary' >
                            <div className="clearfix">
                                <div style={{ float: "left" }}>Next</div>
                                <div style={{ float: "right" }}><FaArrowRight /></div>
                            </div>
                        </Button>
                    </div>
                </Form>
            </Formik>
        </>
    )
}

interface businessFunctionProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}