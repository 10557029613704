import css from './health.module.css';
import { FaAngleLeft } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";
import Loading from "../utils/Loading";
import { fetchGenders } from '../services/LinqrsApi';
import { AxiosResponse } from 'axios';
import { GENDER } from '../utils/GlobalConstants';
import { HealthContext } from './healthContext';
import { GenderDTO } from '../services/services.model';

export default function HealthGender(props: healthGendersProps) {

    const { state, dispatch } = useContext(HealthContext);
    const [genders, setGenders] = useState<GenderDTO[]>([]);

    useEffect(() => {

        const fetchGendersDataAsync = async () => {
            var genderSessionData = sessionStorage.getItem(GENDER);

            if (genderSessionData === null) {
                const response: AxiosResponse<GenderDTO[]> = await fetchGenders();
                var data = response.data.filter(x => x.description === 'Female' || x.description === 'Male');
                sessionStorage.setItem(GENDER, JSON.stringify(data));
                setGenders(data);
            }
            else {
                setGenders(JSON.parse(genderSessionData));
            }
        }

        fetchGendersDataAsync();

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function setGender(gender: GenderDTO) {

        gender.isSelected = !gender.isSelected;

        dispatch({
            type: "HEALTH_GENDER",
            payload: gender
        })

        props.onClick();
    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>Biological Male or female?</div>
            </div>
            {
                genders && genders.length > 0 ? (<div className={css.container}>
                    {
                        genders.map((gender) =>
                            <div className={gender.genderId === state.Gender?.genderId ? 'mb-3 ' + css.containerUnSelected : css.containerSelected + ' mb-3'} key={gender.genderId} onClick={() => setGender(gender)}>
                                <span className={css.divSpan}>{gender.description}</span>
                            </div>
                        )
                    }
                </div>) :
                    <Loading />
            }
        </>
    )
}

interface healthGendersProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}