import { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LinqrsHeaderLogo from './assets/Linqrs.png';
import { headerValue } from "./global";
import './Header.css';
import Help from "./utils/Help";

export default function Header(props: headerValue) {

    const [showModal, setShowModal] = useState<boolean>(false);

    return (
      <div className="sticky-inner">
          <div className="MenuWrap">
                <NavLink className="ListItem" to="/">
                    <img src={LinqrsHeaderLogo} height="30" alt="Linqrs" />
                </NavLink>
                <div className="MiddleItem">
                    <ProgressBar variant="ProgressBar" now={props.now} hidden={props.hidden} label={props.percentage} />
               </div>
                <div className="LastItem" onClick={() => setShowModal(true)}>FAQ</div>
                <Help show={showModal} hide={() => setShowModal(false)} />
            </div>
      </div>
    )
}