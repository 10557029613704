import css from './auto.module.css';
import { FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import Button from '../utils/Button';
import { useContext, useEffect, useRef, useState } from 'react';
import { AutoContext } from './autoContext';
import { Typeahead } from 'react-bootstrap-typeahead';
import { fetchInsuranceProviders } from '../services/LinqrsApi';
import Loading from '../utils/Loading';
import axios, { AxiosResponse } from "axios";
import { INSURANCE_PROVIDERS } from '../utils/GlobalConstants';
import { InsuranceProviderDTO } from '../services/services.model';

export default function AutoCarrier(props: autoCarrierProps) {
    const { state, dispatch } = useContext(AutoContext);
    const [carrier, setCarrier] = useState(state.InsuranceProvider || undefined);
    const [carriers, setCarriers] = useState<InsuranceProviderDTO[]>([]);
    const [errors, setErrors] = useState('');

    const [hiddenOther, setHiddenOther] = useState(() => {
        if (state.InsuranceProvider && state.InsuranceProvider !== null || state.InsuranceProvider !== undefined) {
            return state.InsuranceProvider.insuranceProviderId !== -1;
        }
        return true;
    });

    const [hiddenDefaultValue, setHiddenDefaultValue] = useState<string>(() => {
        if (state.InsuranceProvider && state.InsuranceProvider !== null || state.InsuranceProvider !== undefined) {
            return state.InsuranceProvider.insuranceProviderId !== -1 ? '' : state.InsuranceProvider.insuranceProviderName;
        }
        return '';
    });

    const currentOtherInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {

        const fetchInsuranceProviderDataAsync = async () => {

            var insuranceProvidersSessionData = sessionStorage.getItem(INSURANCE_PROVIDERS);

            if (insuranceProvidersSessionData === null) {
                const response: AxiosResponse<InsuranceProviderDTO[]> = await fetchInsuranceProviders();
                var data = response.data;
                data.push({
                    insuranceProviderId: -1,
                    insuranceProviderName: 'Other',
                    isActive: true
                });
                sessionStorage.setItem(INSURANCE_PROVIDERS, JSON.stringify(data));
                setCarriers(data);
            }
            else {
                setCarriers(JSON.parse(insuranceProvidersSessionData));
            }
        }

        fetchInsuranceProviderDataAsync();
        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);
    }, []);

    function SetCarrier() {

        if (carrier === null || carrier == undefined) {
            setErrors('Insurance carrier is required');
            return;
        }

        dispatch({
            type: "AUTO_CARRIER",
            payload: carrier
        })

        props.onClick();
    }

    function NoSetCarrier() {

        dispatch({
            type: "AUTO_CARRIER",
            payload: undefined
        })

        props.onClick();
    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>Who is your current insurance carrier?</div>
            </div>
            {
                carriers && carriers.length > 0 ?
                    (
                        <div className='mb-3'>
                            <div className='mb-2'>
                                <Typeahead
                                    id='typeahead'
                                    options={carriers} onChange={
                                        actor => {
                                            const carrierDto: InsuranceProviderDTO = actor[0] as InsuranceProviderDTO;
                                            setErrors('');

                                            if (carrierDto === null || carrierDto == undefined) {
                                                if (currentOtherInputRef.current !== null) {
                                                    currentOtherInputRef.current.value = '';
                                                }
                                                setCarrier(undefined);
                                                setHiddenOther(true);
                                            }
                                            else if (carrierDto.insuranceProviderId === -1) {
                                                setCarrier(undefined);
                                                setHiddenOther(false);
                                            }
                                            else {
                                                if (currentOtherInputRef.current !== null) {
                                                    currentOtherInputRef.current.value = '';
                                                }
                                                setHiddenOther(true);
                                                setCarrier(carrierDto);
                                            }
                                        }}
                                    defaultSelected={carrier === undefined ? undefined : carriers.slice(carriers.findIndex(x => x.insuranceProviderId === state.InsuranceProvider?.insuranceProviderId), carriers.findIndex(x => x.insuranceProviderId === state.InsuranceProvider?.insuranceProviderId) + 1)}
                                    labelKey="insuranceProviderName"
                                    filterBy={['insuranceProviderName']}
                                    placeholder="Search current insurance carrier (pick 'Other' if N/A)"
                                    minLength={1} style={{ height: "3.125rem" }} />
                                {errors ? (
                                    <div style={{ color: "red", fontSize: "0.875rem" }}>{errors}</div>
                                ) : null}
                            </div>
                            <div className='mb-2' hidden={hiddenOther} >
                                <input ref={currentOtherInputRef} type="text" defaultValue={hiddenDefaultValue} placeholder='Type insurance carrier here ...' style={{ height: "3.125rem", width: "100%" }}
                                    onChange={(e) => {

                                        if (e.target.value === '' || e.target.value === null || e.target.value === undefined) {
                                            setCarrier(undefined)
                                            return;
                                        }

                                        setCarrier({
                                            insuranceProviderId: -1,
                                            insuranceProviderName: e.target.value,
                                            isActive: true
                                        })
                                    }

                                    } />
                            </div>
                            <div className={css.divCorner} onClick={NoSetCarrier} style={{ width: "30%" }}>
                                <span className={css.divSpan} >None</span>
                            </div>
                            <div className={css.divNext}>
                                <Button type="button" className='btn btn-primary' onclick={SetCarrier}>
                                    <div className="clearfix">
                                        <div style={{ float: "left" }}>Next</div>
                                        <div style={{ float: "right" }}><FaArrowRight /></div>
                                    </div>
                                </Button>
                            </div>
                        </div>

                    ) : <Loading />
            }
        </>
    )
}

interface autoCarrierProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}