
import React, { createContext } from "react";
import { InitialDateOfBirth } from "../utils/global.functions";
import { healthQuotoDto } from "./health.model";



export const HealthContext = createContext<{
    state: healthQuotoDto;
    dispatch: React.Dispatch<any>;
}>({
    state: {
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        PlaceId: '',
        FullAddress: '',
        Languages: [],
        BirthDate: InitialDateOfBirth(),
        Bundles: [],
    },
    dispatch: () => null
});