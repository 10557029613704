import css from './business.module.css';
import Button from '../utils/Button';
import { FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { NavLink } from "react-router-dom";
import NameField from '../utils/NameField';
import { BusinessContext } from './businessContext';

export default function BusinessFullName(props: businessFullNameProps) {

    const { state, dispatch } = useContext(BusinessContext);
    const [firstName, setFirstName] = useState(state.FirstName);
    const [lastName, setLastName] = useState(state.LastName);

    const businessNameSchema = Yup.object().shape({
        firstName: Yup.string().trim().min(2, 'First Name too Short!').max(50, 'First Name too Long!').required('First Name Required'),
        lastName: Yup.string().trim().min(2, 'Last Name tooo Short!').max(50, 'Last Name too Long!').required('Last Name Required')
    });

    useEffect(() => {

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>What is your name?</div>
            </div>
            <Formik
                initialValues={{
                    firstName: firstName,
                    lastName: lastName
                }}
                validationSchema={businessNameSchema}
                onSubmit={values => {

                    dispatch({
                        type: "BUSINESS_FULL_NAME",
                        payload: {
                            FirstName: values.firstName,
                            LastName: values.lastName
                        }
                    })

                    props.onClick();
                }}
            >
                <Form>
                    <NameField name='firstName' placeholder='First Name' />
                    <NameField name='lastName' placeholder='Last Name' />
                    <div className={css.divNext}>
                        <Button type="submit" className='btn btn-primary' >
                            <div className="clearfix">
                                <div style={{ float: "left" }}>Next</div>
                                <div style={{ float: "right" }}><FaArrowRight /></div>
                            </div>
                        </Button>
                    </div>
                </Form>
            </Formik>
        </>
    )
}

interface businessFullNameProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}