import css from './auto.module.css';
import { useContext, useEffect, useState } from "react";
import Loading from "../utils/Loading";
import { FaAngleLeft } from "react-icons/fa";
import { MARITAL_STATUS_ARRAY } from "../utils/GlobalConstants";
import { AutoContext } from "./autoContext";


export default function AutoMarital(props: propsAutoMarital) {

    const { state, dispatch } = useContext(AutoContext);
    const [maritalStatusArray, setMaritalStatusArray] = useState<string[]>(MARITAL_STATUS_ARRAY);

    useEffect(() => {
        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetMaritalStatus(marital: string) {

        dispatch({
            type: "AUTO_MARITAL",
            payload: marital
        })

        props.onClick();
    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>What is your marital status?</div>
            </div>
            {
                maritalStatusArray && maritalStatusArray.length > 0 ? (<div className={css.container}>
                    {
                        maritalStatusArray.map((marital) =>
                            <div className={(marital === state.MaritalStatus && (state.MaritalStatus !== undefined && state.MaritalStatus)) 
                            ? 'mb-3 ' + css.container50UnSelected : css.container50Selected + ' mb-3'} key={marital} onClick={() => SetMaritalStatus(marital)}>
                                {marital}
                            </div>
                        )
                    }
                </div>) :
                    <Loading />
            }
        </>
    )
}

interface propsAutoMarital {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}