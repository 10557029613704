import { ErrorMessage, Field, useFormikContext } from "formik";
import "./DateField.css"

export default function DateField(props: dateFieldProps) {

    const { errors, touched } = useFormikContext<any>();

    return (<div className='mb-3'>
        <Field type="date" name={props.name} id={props.name} className="date-field" />
        {errors[props.name] && touched[props.name] ? (
            <div className="date-field-div"><ErrorMessage name={props.name} /></div>
        ) : null}
    </div>)
}

interface dateFieldProps {
    name: string;
}