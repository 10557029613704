import { useContext, useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { HEIGHT_ARRAY } from "../utils/GlobalConstants";
import Loading from "../utils/Loading";
import css from './term.module.css';
import { TermContext } from "./termContext";


export default function TermHeight(props: termHeightProps) {
    const { state, dispatch } = useContext(TermContext);
    const [heightArray, setHeightArrays] = useState<string[]>(HEIGHT_ARRAY);

    useEffect(() => {

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetHeight(height: string) {

        dispatch({
            type: "TERM_HEIGHT",
            payload: height
        });

        props.onClick();
    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>How tall are you?</div>
            </div>
            {
                heightArray && heightArray.length > 0 ? (<div className={css.container}>
                    {
                        heightArray.map((height) =>
                        <div className={(height === state.Height && (state.Height !== undefined && state.Height)) 
                            ? 'mb-3 ' + css.containerUnSelected : css.containerSelected + ' mb-3'} key={height} onClick={() => SetHeight(height)}>
                                {height}
                            </div>
                        )
                    }
                </div>) :
                   <Loading />

            }
        </>
    )
}

interface termHeightProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}