import css from './business.module.css';
import { useContext, useEffect, useState } from "react";
import Loading from "../utils/Loading";
import { FaAngleLeft } from "react-icons/fa";
import { InsuranceContext } from "../insurance/insuranceContext";
import { BusinessContext } from "./businessContext";
import { RISK_ARRAY } from "../utils/GlobalConstants";

export default function BusinessRisk(props: businessRiskProps) {

    const { state, dispatch } = useContext(BusinessContext);
    const context = useContext(InsuranceContext);
    const [riskArray, setRiskArray] = useState<string[]>(RISK_ARRAY);

    let titleDescr = `How would you describe your business?`;

    useEffect(() => {

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetRisks(risk: string) {

        dispatch({
            type: "BUSINESS_RISK",
            payload: risk
        })

        props.onClick();
    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>{titleDescr}</div>
            </div>
            {
                riskArray && riskArray.length > 0 ? (<div className={css.container}>
                    {
                        riskArray.map((risk) =>
                            <div className={risk === state.BusinessRisk ? 'mb-3 ' + css.containerUnSelected : css.containerSelected + ' mb-3'} key={risk} onClick={() => SetRisks(risk)}>
                                {risk}
                            </div>
                        )
                    }
                </div>) :
                  <Loading />
            }
        </>
    )
}

interface businessRiskProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}