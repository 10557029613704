import { useNavigate } from "react-router-dom";
import css from './insurance.module.css';
import { useContext, useEffect, useState } from "react";
import Loading from "../utils/Loading";
import Button from "../utils/Button";
import { fetchQuoteTypes } from "../services/LinqrsApi";
import { AxiosResponse } from "axios";
import { InsuranceContext } from "./insuranceContext";
import { QuoteTypeDTO } from "../services/services.model";
import { FaAngleLeft, FaArrowRight } from "react-icons/fa";
import { QUOTE_TYPE } from "../utils/GlobalConstants";


export default function InsuranceBundle(props: insuranceBundleProps) {

    const { state, dispatch } = useContext(InsuranceContext);
    const [quoteTypes, setQuoteTypes] = useState<QuoteTypeDTO[]>([]);

    useEffect(() => {

        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

        const fetchInsuranceProviderDataAsync = async () => {
            var quoteTypeSessionData = sessionStorage.getItem(QUOTE_TYPE);

            if (quoteTypeSessionData === null) {
                const response: AxiosResponse<QuoteTypeDTO[]> = await fetchQuoteTypes();
                const data = response.data;
                sessionStorage.setItem(QUOTE_TYPE, JSON.stringify(data));
                setQuoteTypes(data.filter(x => x.wizardEnabled && x.quoteMenuTypeKey !== state.QuoteType.quoteMenuTypeKey));
            }
            else {
                const sessionData = JSON.parse(quoteTypeSessionData) as QuoteTypeDTO[];
                setQuoteTypes(sessionData.filter(x => x.wizardEnabled && x.quoteMenuTypeKey !== state.QuoteType.quoteMenuTypeKey));
            }
        }

        fetchInsuranceProviderDataAsync();

    }, []);

    function SetBundles(quoteType: QuoteTypeDTO) {

        if (state.Bundles.findIndex(b => b.quoteMenuTypeKey === quoteType.quoteMenuTypeKey) > -1) {

            const currentIndex = state.Bundles.findIndex(b => b.quoteMenuTypeKey === quoteType.quoteMenuTypeKey);
            state.Bundles[currentIndex].isSelected = !state.Bundles[currentIndex].isSelected;

            dispatch({
                type: "QUOTE_BUNDLES",
                payload: [...state.Bundles]
            })
        }
        else {
            quoteType.isSelected = !quoteType.isSelected;

            dispatch({
                type: "QUOTE_BUNDLES",
                payload: [...state.Bundles, quoteType]
            })
        }

    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-1'>
                <div className={css.divCenterText}>Bundle?</div>
                <div className={css.divCenterText}>Choose additional products you would like the agent to quote.</div>
            </div>
            <div className="mb-3">
                <div className={css.divCenterTextSmall}>
                    On average, members save $672/year!
                </div>
            </div>
            {
                quoteTypes && quoteTypes.length > 0 ? (<div className={css.container}>
                    {
                            quoteTypes.map((quoteType) =>
                                <div className={state.Bundles && state.Bundles.findIndex(b => b.quoteMenuTypeKey === quoteType.quoteMenuTypeKey && b.isSelected) > -1 ? 'mb-3 ' + css.containerUnSelected : css.containerSelected + ' mb-3'} key={quoteType.quoteMenuTypeKey} onClick={() => SetBundles(quoteType)}>
                                    {quoteType.description}
                                </div>
                            )
                        }
                </div>) : <Loading />
            }
            <div className={css.divNext}>
                <Button type="button" className='btn btn-primary' onclick={() => {
                    props.updateBundle();
                    props.onClick();
                }}>
                    <div className="clearfix">
                        <div style={{ float: "left" }}>Next</div>
                        <div style={{ float: "right" }}><FaArrowRight /></div>
                    </div>
                </Button>
            </div>
        </>
    )
}

interface insuranceBundleProps {
    updateBundle(): void;
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}