import { Link, Navigate } from "react-router-dom";
import css from './business.module.css';
import { useContext, useEffect, useState } from "react";
import Loading from "../utils/Loading";
import { FaAngleLeft } from "react-icons/fa";
import { BusinessContext } from "./businessContext";
import { YES_OR_NO_ARRAY } from "../utils/GlobalConstants";


export default function BusinessClaimsLast3Years(props: businessClaimsLast3YearsProps) {

    const { state, dispatch } = useContext(BusinessContext);
    const [yesOrNoArray, setYesOrNoArrays] = useState<string[]>(YES_OR_NO_ARRAY);

    useEffect(() => {
        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetClaimsLast3Years(yesOrNo: string) {

        dispatch({
            type: "BUSINESS_CLAIMS_LAST_3_YEARS",
            payload: yesOrNo === 'Yes'
        })

        props.onClick();
    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>To expedite and get you to the right agent, have you had any claims in
the last 3 years?</div>
            </div>
            {
                yesOrNoArray && yesOrNoArray.length > 0 ? (<div className={css.container}>
                    {
                        yesOrNoArray.map((yesOrNo) =>
                            <div className={(yesOrNo === 'Yes' && (state.ClaimsLast3Years !== undefined && state.ClaimsLast3Years)) || (yesOrNo === 'No' && (state.ClaimsLast3Years !== undefined && !state.ClaimsLast3Years)) ? 'mb-3 ' + css.container50UnSelected : css.container50Selected + ' mb-3'} key={yesOrNo} onClick={() => SetClaimsLast3Years(yesOrNo)}>
                                {yesOrNo}
                            </div>
                        )
                    }
                </div>) :
                  <Loading />
            }
        </>
    )
}

interface businessClaimsLast3YearsProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}