import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { FaAngleLeft, FaArrowRight } from "react-icons/fa";
import { fetchViolations } from "../services/LinqrsApi";
import { ViolationDTO } from "../services/services.model";
import Button from "../utils/Button";
import { VIOLATIONS } from "../utils/GlobalConstants";
import Loading from "../utils/Loading";
import css from './auto.module.css';
import { AutoContext } from "./autoContext";

export default function AutoViolations(props: autoViolationsProps) {
    const { state, dispatch } = useContext(AutoContext);
    const [violations, setViolation] = useState<ViolationDTO[]>([]);

    useEffect(() => {

        const fetchViolationsDataAsync = async () => {

            var violationsSessionData = sessionStorage.getItem(VIOLATIONS);

            if (violationsSessionData === null) {
                const response: AxiosResponse<ViolationDTO[]> = await fetchViolations();
                var data = response.data;
                sessionStorage.setItem(VIOLATIONS, JSON.stringify(data));
                setViolation(data);
            }
            else {
                setViolation(JSON.parse(violationsSessionData));
            }
        }

        fetchViolationsDataAsync();
        props.changeHeaderValue(false, ((props.currentIndex / props.totalIndex) * 100), `${props.currentIndex} / ${props.totalIndex}`);

    }, []);

    function SetViolations(violation: ViolationDTO) {

        if (state.Violations.findIndex(v => v.violationId === violation.violationId) > -1) {

            const currentIndex = state.Violations.findIndex(v => v.violationId === violation.violationId);
            state.Violations[currentIndex].isSelected = !state.Violations[currentIndex].isSelected;

            dispatch({
                type: "AUTO_VIOLATIONS",
                payload: [...state.Violations]
            })
        }
        else {
            violation.isSelected = !violation.isSelected;

            dispatch({
                type: "AUTO_VIOLATIONS",
                payload: [...state.Violations, violation]
            })
        }

    }

    return (
        <>
            <div><FaAngleLeft className={css.backArrow} onClick={props.onBackClick} /></div>
            <div className='mb-3'>
                <div className={css.divCenterText}>To expedite and get you to the right agent, please
                    list all violations for drivers in the last 3 years? If none, hit next</div>
            </div>
            {
                violations && violations.length > 0 ? (
                    <div className={css.container}>
                        {
                            violations.map((violation) =>
                                <div className={state.Violations && state.Violations.findIndex(v => v.violationId === violation.violationId && v.isSelected) > -1 ? 'mb-3 ' + css.containerUnSelected : css.containerSelected + ' mb-3'} key={violation.violationId} onClick={() => SetViolations(violation)}>
                                    {violation.description}
                                </div>
                            )
                        }
                    </div>
                ) : <Loading />
            }
            <div className={css.divNext}>
                <Button type="button" className='btn btn-primary' onclick={props.onClick}>
                    <div className="clearfix">
                        <div style={{ float: "left" }}>Next</div>
                        <div style={{ float: "right" }}><FaArrowRight /></div>
                    </div>
                </Button>
            </div>
        </>
    )
}

interface autoViolationsProps {
    onClick(): void;
    onBackClick(): void;
    currentIndex: number;
    totalIndex: number;
    changeHeaderValue(hidden: boolean, now: number, percentage: string): void;
}