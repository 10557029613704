
import React, { createContext } from "react";
import { InitialDateOfBirth } from "../utils/global.functions";
import { termQuotoDto } from "./term.model";



export const TermContext = createContext<{
    state: termQuotoDto;
    dispatch: React.Dispatch<any>;
}>({
    state: {
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        PlaceId: '',
        FullAddress: '',
        Languages: [],
        BirthDate: InitialDateOfBirth(),
        Bundles: [],
    },
    dispatch: () => null
});