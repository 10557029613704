import { autoQuotoDto } from "./auto.model";


export const autoReducer = (state: autoQuotoDto, action: any) => {
  switch (action.type) {

    case "QUOTE_TYPE":
      return {
        ...state,
        QuoteType: action.payload
      };

    case "AUTO_ADD_CARS":
      return {
        ...state,
        VehiclesWeb: [...state.VehiclesWeb, action.payload]
      };

      case "AUTO_DELETE_CARS":
       {
        var vehicles = state.VehiclesWeb.filter(x => x.created !== action.payload.created)
        return {
          ...state,
          VehiclesWeb: [...vehicles]
        };
       }

    case "AUTO_DRIVER":
      return {
        ...state,
        FirstName: action.payload.FirstName,
        LastName: action.payload.LastName,
      };

    case "AUTO_DATE_OF_BIRTH":
      return {
        ...state,
        BirthDate: action.payload
      };

    case "AUTO_GENDER":
      return {
        ...state,
        Gender: action.payload
      };

    case "AUTO_CARRIER":
      return {
        ...state,
        InsuranceProvider: action.payload
      };

    case "AUTO_ADD_DRIVERS":
      return {
        ...state,
        NumberOfDrivers: action.payload
      };

    case "AUTO_VIOLATIONS":
      return {
        ...state,
        Violations: action.payload
      };

    case "AUTO_EMAIL_ADDRESS":
      return {
        ...state,
        EmailAddress: action.payload
      };
    case "AUTO_ADDRESS":
      return {
        ...state,
        PlaceId: action.payload.placeId,
        FullAddress: action.payload.fullAddress
      };

    case "AUTO_PHONE_NUMBER":
      return {
        ...state,
        PhoneNumber: action.payload
      };

    case "AUTO_LANGUAGES":
      return {
        ...state,
        Languages: action.payload
      };
    case "AUTO_BUNDLES":
      return {
          ...state,
          Bundles: action.payload
      };
    case "AUTO_SR22":
      return {
          ...state,
          Sr22: action.payload
      };
      case "AUTO_MARITAL":
        return {
            ...state,
            MaritalStatus: action.payload
        };
    default:
      return state;
  }
}